<app-toolbar page="search" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''"></app-toolbar>

<div class="form-list" [ngClass]="{tablet: menuType==2}">

<mat-form-field class="w-100" appearance="fill">
  <input placeholder="{{ 'search.title' | translate }}" matInput #searchInput (keyup)="keyup($event)" autocomplete="off" />
</mat-form-field>
<h2 class="search-nodata" *ngIf="empty">{{ 'search.no_data' | translate }}</h2>
<div class="result-body" *ngIf="result$ | async as items">
<mat-list role="list" *ngFor="let type of items | GroupBySearchType">

  <mat-list-item
    role="listitem"
    class="head-search">
    {{ getMenuAlias(type.label) | translate }}
  </mat-list-item>

  <ng-container
      *ngFor="let item of type.items"
      [ngTemplateOutlet]="card"
      [ngTemplateOutletContext]="{item: item}">
  </ng-container>
</mat-list>
</div>

</div>

<div [hidden]="!waiting">
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</div>

<ng-template #card let-item='item'>
  <mat-list-item class="no-product"  *ngIf="getTypeValue(item) !== 'product'"
    role="listitem" (click)="open(item)">
    <div mat-list-icon
      class="item-img"
      [ngStyle]="{'background-image': 'url(' + (getItemPhoto(item) | pipeWebp) + ')'  }">
    </div>
    <div mat-line><h2>{{ getItemTitle(item) }}</h2></div>
    <div mat-line>{{ getItemSubtitle(item) }}</div>
  </mat-list-item>

  <mat-card *ngIf="getTypeValue(item) === 'product'"
    class="card-bottom">
    <div (click)="open(item)">
      <mat-card-header>
        <div mat-card-avatar class="item-img-product"
          [ngStyle]="{'background-image': 'url(' + (getItemPhoto(item) | pipeWebp) + ')'}" >
        </div>
        <mat-card-title> {{ getItemTitle(item) }}</mat-card-title>
        <mat-card-subtitle>{{ getItemSubtitle(item) }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-footer class="product-price">
        {{ (getItemPrice(item) | number:'1.2-2').replace(',',' ').replace('.',',') }}{{ currencysymbol }}
      </mat-card-footer>
    </div>
  </mat-card>
</ng-template>
