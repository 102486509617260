import { Component, OnInit, ContentChild, TemplateRef } from '@angular/core';
import { GrpcNewsLibService } from '../../service/grpc/news/grpc-news-lib.service';
import { News } from '../../libs/proto/commUnity_pb';
import { GrpcLibService, GrpcLibServiceOption } from '../../service/grpc/grpc-lib.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLibService } from '../../service/user/user-lib.service';
import { Subject, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StorageLibService } from '../../service/storage/storage-lib.service';
import { NewsPathConverter } from '../../service/conversion/news/news-path-converter';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.sass']
})
export class NewsComponent implements OnInit {

  news$: Promise<News[]>;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;

  constructor(
    private route: Router,
    private grpcNewsLib: GrpcNewsLibService,
    private userLib: UserLibService,
    private translate: TranslateService,
    private storeLib: StorageLibService,
    private newsLib: NewsPathConverter,
    private detector: DeviceLibService,
  ) {
    userLib.setPageLanguage( this.translate );
  }

  /**
   * reset cache news
   */
  private resetCache() {
    switch (this.newsLib.newsIndex) {
      case '2':
        this.storeLib.cache.news2 = false;break;
      case '3':
        this.storeLib.cache.news3 = false;break;
      case '4':
        this.storeLib.cache.news4 = false;break;
      default:
        this.storeLib.cache.news = false;break;
    }
  }

  private get offlineNews() {
    switch (this.newsLib.newsIndex) {
      case '2':
        return this.storeLib.cache.news2;
      case '3':
        return this.storeLib.cache.news3;
      case '4':
        return this.storeLib.cache.news4;
      default:
        return this.storeLib.cache.news;
    }
  }

  ngOnInit(): void {
    const newsk = 'news-s' + this.newsLib.newsIndex;
    if (this.storeLib.get(newsk)) {
      this.storeLib.set(newsk, null);
      this.resetCache();
    }
    this.news$ = this.newsLib.fnGetNews({
      Offline: this.offlineNews || false,
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });
    
    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }

  open(id: string){
    this.route.navigate(['/new' + this.newsLib.newsIndex, id]);
  }

  get dateFormat(): string {
    return this.userLib.dateFormat;
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }
}
