<app-toolbar page="product-confirm" data="{{ product?.getId() }}"></app-toolbar>

<div class="form-list">

<div class="product-page">
<mat-card class="product-card">
  <mat-card-header>
    <mat-card-title>{{ product?.getName() }}</mat-card-title>
  </mat-card-header>
  <mat-card-footer [class]="'product-price'">
    <span *ngIf="product_qty">{{ 'product.qty' | translate }} {{ product_qty }}</span>
    {{ ((product?.getPrice() | number:'1.2-2') || '').replace(',',' ').replace('.',',') }}{{ currencysymbol }}
  </mat-card-footer>
</mat-card>

<div class="top-image"
    [ngStyle]="{'background-image': 'url(' + product?.getImagesList()[0] +')'}">
  </div>

<div #boxOption class="box-options">
  <div class="row" *ngIf="(colorL[0] || {}).label">
    <div class="col1">{{ 'product.color_label' | translate }}</div>
    <div class="col2">
      <mat-form-field>
        <mat-select [(ngModel)]="orderForm.color">
          <mat-option
            *ngFor="let c of colorL"
            [value]="c.key">{{c.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="product?.getSizesList().join(',')">
    <div class="col1">{{ 'product.size_label' | translate }}</div>
    <div class="col2">
      <mat-form-field>
        <mat-select [(ngModel)]="orderForm.size">
          <mat-option
            *ngFor="let s of product?.getSizesList()"
            [value]="s">{{s}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col1">{{ 'product-confirm.quantity' | translate }}</div>
    <div class="col2">
      <mat-form-field>
        <mat-select [(ngModel)]="orderForm.qtySelect" >
          <mat-option (click)="updateOrder()"
            *ngFor="let s of '1,2,3,4,5,product-confirm.Other'.split(',')"
            [value]="s">{{s | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row last" >
    <div class="col1">{{ 'product-confirm.total' | translate }}</div>
    <div class="col2">
      <div class="total">
        {{ ((total | number:'1.2-2') || '').replace(',',' ').replace('.',',') }} {{ currencysymbol }}
      </div>
    </div>
  </div>
</div>
</div>
<div class="bottom">
  <button (click)="addProductToCart()" mat-raised-button [class]="'button-confirm'">{{ 'product-confirm.button' | translate }}</button>
</div>

</div>

<app-ctl-qty-dialog
  [showQty]="orderForm"
  (okclick)="qtyOK()"
  *ngIf="orderForm.show"></app-ctl-qty-dialog>
