<app-toolbar page="about" [ml]="menuType==2?'15%':''"></app-toolbar>
<div *ngIf="this.hasAboutUs && menuType!=2">
  <img class="photo" src="{{this.photo | pipeWebp}}"/>

  <div class="about-us">

    <h1 class="title">
        {{this.title}}
    </h1>
    <div class="short-desc" [innerHTML]="this.shortDesc | pipeDescription">
    </div>
    <div class="desc" [innerHTML]="this.desc| pipeDescription">
    </div>

    <div class="place" *ngIf="placeId">
        <a class="pl-0"
            mat-flat-button
            [routerLink]="[ '/place/about/0' , placeId]" routerLinkActive="active">
            <mat-icon>place</mat-icon>
            {{ 'about.place' | translate}}
        </a>
    </div>
  </div>
</div>

<div class="mode-tablet" *ngIf="this.hasAboutUs && menuType==2">
  <div class="left">
    <img class="photo" src="{{this.photo | pipeWebp}}"/>
  </div>
  <div class="right">
    <h1 class="title">
        {{this.title}}
    </h1>
    <div class="short-desc" [innerHTML]="this.shortDesc | pipeDescription">
    </div>
    <div class="desc" [innerHTML]="this.desc| pipeDescription">
    </div>

    <div class="place" *ngIf="placeId">
        <a class="pl-0"
            mat-flat-button
            [routerLink]="[ '/place/about/0' , placeId]" routerLinkActive="active">
            <mat-icon>place</mat-icon>
            {{ 'about.place' | translate}}
        </a>
    </div>
  </div>
</div>
