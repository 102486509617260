<app-toolbar page="menu" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''"></app-toolbar>

<div class="form-list" [ngClass]="{tablet: menuType==2}">

<div class="product-page">
<mat-card class="product-card">
  <mat-card-header>
    <mat-card-title>{{ product?.getName() }}</mat-card-title>
  </mat-card-header>
  <mat-card-footer [class]="'product-price'">
    <span *ngIf="product_qty">{{ 'menu.qty' | translate }} {{ product_qty }}</span>
    {{ ((product?.getPrice() | number:'1.2-2') || '').replace(',',' ').replace('.',',') }}{{ currencysymbol }}          </mat-card-footer>
</mat-card>

<div class="content">
<ng-image-slider
  class="img-slider"
  [imageSize]="imageSize"
  [manageImageRatio]="true"
  [images]="imageObject" #nav>
</ng-image-slider>

<p [innerHTML]="product?.getDescription() | pipeDescription">
</p>
</div>

<div class="bottom">
  <button
    *ngIf="((product?.getQtyn() && product?.getQt() > 0) || !product?.getQtyn() || (product?.getQt() === -1)) && has_table && allow_add"
    (click)="clickButton()"
    mat-raised-button
    [class]="'button-buy'">
    {{ 'menu.button' | translate }}
  </button>
</div>

</div>
