<app-toolbar page="stream" pageTitle="{{ ptitle }}" ></app-toolbar>
<div class="form-list stream">

<video class="ios" autoplay controls #videoPlayer *ngIf="!isChromeOrAndroid && autostart">
    <source [src]="url" type="application/x-mpegURL" />
    Browser not supported
</video>

<video class="ios" controls #videoPlayer *ngIf="!isChromeOrAndroid && !autostart">
  <source [src]="url" type="application/x-mpegURL" />
  Browser not supported
</video>

<div *ngIf="!url && isChromeOrAndroid">
  loading...
</div>

<video
  *ngIf="url && isChromeOrAndroid"
  id="myvideo" class="video-js vjs-default-skin">
  <source type="video/mp4">
</video>

</div>
