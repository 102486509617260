import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { Observable, Subscriber } from 'rxjs';
import { News, Empty } from '../../../libs/proto/commUnity_pb';
import * as grpcWeb from 'grpc-web';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { NewsConversionService } from '../../conversion/news/news-conversion.service';

@Injectable({
  providedIn: 'root'
})
export class GrpcNewsLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: NewsConversionService,
  ) { }

  getNews(option?: GrpcLibServiceOption): Promise<News[]>{
    return new Observable<News[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getNewsOffline(opt);
      } else {
        this._getNewsOnline(opt);
      }
    }).toPromise();
  }

  private _getNewsOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('news-s') || this.storLib.get('news')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getNewsOnline(option: GrpcLibServiceOption){
    let t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getNews(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getNewsOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getNewsOnlineStatus(s, option);
      })
      .on('data', (r: News) => {
        this._getNewsOnlineData(r, option);
      })
      .on('end', () => {
        this._getNewsOnlineEnd(option);
      });
  }

  private _getNewsOnlineData(ret: News, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getNewsOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getNewsOnline(option);
    }, option);
  }

  private _getNewsOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getNewsOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.news = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'news', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getNewsOnline(option);
      }, option);
    }
  }

  getNews2(option?: GrpcLibServiceOption): Promise<News[]>{
    return new Observable<News[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getNews2Offline(opt);
      } else {
        this._getNews2Online(opt);
      }
    }).toPromise();
  }

  private _getNews2Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('news-s2') || this.storLib.get('news2')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getNews2Online(option: GrpcLibServiceOption){
    let t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getNews2(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getNews2OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getNews2OnlineStatus(s, option);
      })
      .on('data', (r: News) => {
        this._getNews2OnlineData(r, option);
      })
      .on('end', () => {
        this._getNews2OnlineEnd(option);
      });
  }

  private _getNews2OnlineData(ret: News, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getNews2OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getNews2Online(option);
    }, option);
  }

  private _getNews2OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getNews2OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.news2 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'news2', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getNews2Online(option);
      }, option);
    }
  }

  getNews3(option?: GrpcLibServiceOption): Promise<News[]>{
    return new Observable<News[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getNews3Offline(opt);
      } else {
        this._getNews3Online(opt);
      }
    }).toPromise();
  }

  private _getNews3Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('news-s3') || this.storLib.get('news3')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getNews3Online(option: GrpcLibServiceOption){
    let t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getNews3(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getNews3OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getNews3OnlineStatus(s, option);
      })
      .on('data', (r: News) => {
        this._getNews3OnlineData(r, option);
      })
      .on('end', () => {
        this._getNews3OnlineEnd(option);
      });
  }

  private _getNews3OnlineData(ret: News, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getNews3OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getNews3Online(option);
    }, option);
  }

  private _getNews3OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getNews3OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.news3 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'news3', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getNews3Online(option);
      }, option);
    }
  }

  getNews4(option?: GrpcLibServiceOption): Promise<News[]>{
    return new Observable<News[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getNews4Offline(opt);
      } else {
        this._getNews4Online(opt);
      }
    }).toPromise();
  }

  private _getNews4Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('news-s4') || this.storLib.get('news4')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getNews4Online(option: GrpcLibServiceOption){
    let t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getNews4(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getNews4OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getNews4OnlineStatus(s, option);
      })
      .on('data', (r: News) => {
        this._getNews4OnlineData(r, option);
      })
      .on('end', () => {
        this._getNews4OnlineEnd(option);
      });
  }

  private _getNews4OnlineData(ret: News, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getNews4OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getNews4Online(option);
    }, option);
  }

  private _getNews4OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getNews4OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.news4 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'news4', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getNews4Online(option);
      }, option);
    }
  }

}
