<app-toolbar page="shop" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''"></app-toolbar>

<div
  (scroll)="clearScrollFlag()"
  class="form-list" [ngClass]="{tablet: menuType==2}" *ngIf="products$ | async as products">

  <mat-accordion
    [multi]="true">

    <mat-expansion-panel
    *ngFor="let category of products | GroupByCategory"
      (afterExpand)="expanded(category.id, 1)"
      (afterCollapse)="expanded(category.id, 0)"
      [expanded]="!isCollapsed(category.id)"
      class="product-page"
      >
      <mat-expansion-panel-header
        class="product-category">
        <mat-panel-title>
          {{ category.label }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-card
        *ngFor="let product of category.items"
        [class]="'card-bottom'">
        <div
          [id]="product.getId()"
          (click)="open(product.getId())"  >
          <mat-card-header>
            <div mat-card-avatar class="item-img"
              [ngStyle]="{'background-image': 'url(' + product.getImagesList()[0] + ')'}" >
            </div>
            <mat-card-title> {{ product.getName() }}</mat-card-title>
            <mat-card-subtitle>{{ product.getShortdescription() }}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-footer [class]="'product-price'">
            <span class="qty" *ngIf="(product.getQtyn() && product.getQt() === 0)">{{ 'product.qty0' | translate }}</span>
            <span class="qty" *ngIf="(product.getQtyn() && product.getQt() > 0)">{{ 'product.qty' | translate }}:{{ product.getQt() }}</span>
            {{ (product.getPrice() | number:'1.2-2').replace(',',' ').replace('.',',') }}{{ currencysymbol }}
          </mat-card-footer>
        </div>
      </mat-card>

    </mat-expansion-panel>

  </mat-accordion>
</div>

<ng-template #loading>
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
