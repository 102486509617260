import { Injectable } from '@angular/core';
import { communityConfig } from '../../config/app';
import { MobApp } from '../../libs/proto/mobile_pb';
import { Language, MobileApp } from '../../libs/proto/commUnity_pb';
import { communityAppType } from '../../config/type';
import { Platform } from '@angular/cdk/platform';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class DeviceLibService {

  constructor(
    private detector: DeviceDetectorService,
    private platform: Platform
  ) {  }

  isMobile() {
    let ismobile = this.detector.isMobile();
    if (ismobile && !this.platform.SAFARI) {
        if (this.detector.userAgent.toLowerCase().indexOf('mobile') === -1) {
          ismobile = false;
        }
    }
    return ismobile;
  }
  get orientation() {
    return this.detector.orientation;
  }

  lang(url:string) {
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    let lg = (window.navigator.language || 'en').substr(0, 2);

    // folloing assets/i10n/*.json
    if (url != '') {
      if (url.match(/lg=fr/gi)) { lg = 'fr'; }
      if (url.match(/lg=nl/gi)) { lg = 'nl'; }
      if (url.match(/lg=es/gi)) { lg = 'es'; }
      if (url.match(/lg=pt/gi)) { lg = 'pt'; }
    }
    return lg || 'en';
  }

  // check lang with app lg.
  applang(lg: number): string {
    var ret = this.lang('');
    Object.keys(Language).map( l => {
      if (lg == Language[l]) {
          ret = l.toLowerCase();
      }
    });

    // folloing assets/i10n/*.json
    if (['en', 'fr', 'nl', 'es', 'pt'].indexOf(ret) < 0) { ret = this.lang(''); }
    return ret;
  }
}
