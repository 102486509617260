<app-toolbar page="leaderboard" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''"></app-toolbar>
<div class="form-list" [ngClass]="{tablet: menuType==2}" *ngIf="leaderboard">

  <table class="leader-table" role="table">
    <thead role="rowgroup">
      <tr class="head">
        <th *ngFor="let c of columnsToDisplay" 
          (mwlClick)="SortChange(c)"
          [ngClass]="c">
          <mat-icon [ngClass]="{'sorted': sortcol == c,'desc': sortdirection == 1}">arrow_upward</mat-icon>
          <span>{{ ('leaderboard.' + c) | translate}}</span></th>
      </tr>
    </thead>
    
    <tbody 
      *ngFor="let g of group" role="rowgroup">
      <tr 
        (mwlClick)="expandChange(g)"
        class="group" *ngIf="group.length > 1">
      <td 
        role="cell" [colSpan]="columnsToDisplay.length - 1">
         {{ 'leaderboard.group' | translate }} : {{ g }} </td>
      <td class="indicator">
        <mat-icon [ngClass]="{'expand': gexpand.indexOf(g) == -1}">chevron_left</mat-icon>
      </td>
      </tr>

      <tr class="data"        
        *ngFor="let l of filter_group(g); index as i" 
        [ngClass]="{'collapse': gexpand.indexOf(g) == -1 ,'row-even': (i % 2) != 0, 'row-odd': (i % 2) == 0 }"
        >

        <td 
        *ngFor="let c of columnsToDisplay" 
        role="cell" [ngClass]="c">
          {{ l[c] }} </td>

      </tr>

    </tbody>
  </table>

</div>