import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GrpcPlaceLibService } from '../../service/grpc/place/grpc-place-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { UserLibService } from '../../service/user/user-lib.service';
import { GolfClass, GolfClassLevel, GolfClassType, GolfClassesQuery, GolfTeacher, GolfTheme, Language, Place } from '../../libs/proto/commUnity_pb';
import { Subject, Subscription } from 'rxjs';
import { StorageLibService } from '../../service/storage/storage-lib.service';
import { AddressConversionService } from '../../service/conversion/address/address-conversion.service';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import SwiperCore, { Navigation } from 'swiper';
import { GrpcGolfClassLibService } from 'src/app/service/grpc/golf_classes/grpc-golfclass-lib.service';
import * as moment from 'moment';
import { GrpcGolfClassTypeLibService } from 'src/app/service/grpc/golf_classes/grpc-golfclasstype-lib.service';
import { GrpcGolfClassLevelLibService } from 'src/app/service/grpc/golf_classes/grpc-golfclasslevel-lib.service';
import { GrpcGolfTeacherLibService } from 'src/app/service/grpc/golf_classes/grpc-golfteacher-lib.service';
import { GrpcGolfThemeLibService } from 'src/app/service/grpc/golf_classes/grpc-golftheme-lib.service';
import { ColorConversionService } from 'src/app/service/conversion/color/color-conversion.service';
import { Platform } from '@angular/cdk/platform';
import { DialogServiceService } from 'src/app/service/dialog/dialog-service.service';
import { GolfCardComponent } from './golf_class.card';
import { CalendarView, CalendarEvent } from 'angular-calendar';
import { DateAdapter } from '@angular/material/core';

class dlgSelectClass {
  teacher = '';
  theme = '';
  level = '';
  type = '';

  date1st = moment();
  date = moment();
  selectday = this.date.format('YYYYMMDD');
  
  vdays: moment.Moment[] = [];
  days: string[]=[];
  wdays: string[]=[];

  get monthname(): string {
    return moment.months(this.date.month());
  }
  setupdays() {
    this.date = this.date1st.clone();
    this.selectday = this.date.format('YYYYMMDD');
    var vday = this.date1st.clone();
    this.vdays = [vday.clone()];

    for (var i=0;i<4;i++) {
      var vd = vday.add( 1, 'days');
      this.vdays.push(vd.clone());      
    }
  }

  isselect(d: string): boolean {
    return d == this.selectday;
  }

  times(dlgclasses: GolfClass[]):GolfClass[] {
    if (!dlgclasses) return [];
    dlgclasses[0].getPlacename
    return dlgclasses.filter((e) => e.getDate().toString() == this.date.format('YYYYMMDD'));
  }
}

import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { registerLocaleData, WeekDay } from '@angular/common';

@Component({
  selector: 'app-golf-class',
  templateUrl: './golf_class.component.html',
  styleUrls: ['./golf_class.component.sass']
})
export class GolfClassComponent implements OnInit {

  sizeW = window.innerWidth;
  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;
  menuType = 0;
  private actRouter$: Subscription;

  dlgdisplay = 'none';
  step = 1;
  dlgloading = false;

  dlgteachers: GolfTeacher[];
  dlgthemes: GolfTheme[];
  dlglevels: GolfClassLevel[];
  dlgtypes: GolfClassType[];
  dlgError = '';
  dlgwaitattend = false;
  dlgSelect: dlgSelectClass;
  dlgclasses: GolfClass[];
  myclasses: GolfClass[];

  gclass: GolfClass;
  isios = false;

  classloading = false;
  gcollapse = '';

  T = {};

  showCalendar = true;
  calendarView: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  calendarDate: Date = new Date();
  calendarSelect: Date = new Date();
  calendarRefresh: Subject<any> = new Subject();
  calendarEvents: CalendarEvent[];
  calendarActiveDayIsOpen: boolean;
  dlgclassesAvail: String[];
  dlgclassesfull: String[];

  queryid = '';

  constructor(
    private actRoute: ActivatedRoute,
    public storeLib: StorageLibService,
    public userLib: UserLibService,
    private detector: DeviceLibService,
    public grpcGolfClassLib: GrpcGolfClassLibService,
    private grpcGolfClassTypeLib: GrpcGolfClassTypeLibService,
    private grpcGolfClassLevelLib: GrpcGolfClassLevelLibService,
    private grpcGolfTeacherLib: GrpcGolfTeacherLibService,
    private grpcGolfThemeLib: GrpcGolfThemeLibService,
    public colorlib: ColorConversionService,
    private translate: TranslateService,
    public dlglib: DialogServiceService,
    public route: Router,
    private platform: Platform,
    private dateAdp: DateAdapter<Date>,
  ) {
    this.isios = platform.IOS;
  }

  get currencysymbol() {
    return this.userLib.Data.token?.getCustomer().getCurrencysymbol();
  }
  ngOnInit(): void {
    const thise = this;
    moment.locale(this.userLib.Data.lg);
    this.dateAdp.setLocale(this.userLocale);
    
    if (this.userLocale == 'fr') registerLocaleData(localeFr);
    if (this.userLocale == 'nl') registerLocaleData(localeNl);
    if (this.userLocale == 'es') registerLocaleData(localeEs);
    if (this.userLocale == 'pt') registerLocaleData(localePt);

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
       this.isLandscape = this.landscapeEvt.matches;
       this.menuType = this.getmenuType();
      });

    this.classloading = true;
    this.dlgSelect =  new dlgSelectClass();
    this.dlgSelect.date = moment();
    this.dlgSelect.setupdays();

    this.classloading = true;
    this.actRouter$ = this.actRoute.paramMap.subscribe(p => {
    this.queryid = p.get('id');
    this.get_dlg_teachers(() => {
      this.updateFilter();
    });

    });

    this.translate.get([
      'golf_class.err_not_found',
      'golf_class.err_title',
      'golf_class.ok_attend',
      'golf_class.err_attend',
      'golf_class.attend_title',
      'golf_class.cancel_title',
      'golf_class.cancel_msg',
      'golf_class.button_cancel',
      'golf_class.err_cancel',
      'golf_class.ok_cancel',
    ]).toPromise().then( (v) => {
      this.T = v;
    });
  }

  getmylist(callback:() => void) {
    this.grpcGolfClassLib.getMyGolfClasses({
      Offline: this.storeLib.cache.myGolfClass || false,
    }).then( (v) => {
      this.myclasses = v;
    }).finally( () => {
      callback();
    });    
  }

  get format_hour() {
    return GolfCardComponent.format_hour;
  }

  get teachername() {
    return GolfCardComponent.teachername;
  }

  get teacherphoto() {
    return GolfCardComponent.teacherphoto;
  }

  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }

  get_dlg_class(callback:() => void) {
    let req = new GolfClassesQuery();
    req.setTeacherid(this.dlgSelect.teacher);
    req.setThemeid(this.dlgSelect.theme);
    req.setLevelid(this.dlgSelect.level);
    req.setTypeid(this.dlgSelect.type);    
    //req.setMonth(this.dlgSelect.date.month());
    //req.setYear(this.dlgSelect.date.year());

    this.grpcGolfClassLib.getGolfClasses({
      Offline: false,
      call: {
        req:req,
      }
    }).then( v => {
       this.dlgclasses = v;

       this.dlgclassesAvail =  v.filter((e) => (e.getQuotamax() == 0) || (e.getNbattendees() < e.getQuotamax()) ).map((e) => e.getDate().toString());
       this.dlgclassesfull =  v.filter((e) => (e.getQuotamax() > 0) && (e.getNbattendees() == e.getQuotamax()) ).map((e) => e.getDate().toString());

    }).catch( (e) =>{
      this.dlgError = e;
    }).finally( () => {
      callback();
    });
  }
  get desktopmode() {
    return desktopMode;
  }
  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {    
    // 90 = time + margin
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { 
          return 2;  
        }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }

  get_dlg_teachers(callback: () =>void) {
    this.grpcGolfTeacherLib.getGolfTeachers({
      Offline: this.storeLib.cache.golfTeacher || false,
    }).then( v => {
      this.dlgteachers = v;
    }).finally( () => {
      callback();
    });    
  }

  get_dlg_theme(callback: () => void) {
    this.grpcGolfThemeLib.getGolfThemees({
      Offline: this.storeLib.cache.golfTheme || false,
    }).then( v => {
      this.dlgthemes = v;
    }).finally( () => {
      callback();
    });    
  }

  get_dlg_level(callback: () => void) {
    this.grpcGolfClassLevelLib.getGolfClassLeveles({
      Offline: this.storeLib.cache.golfClassLevel || false,
    }).then( v => {
      this.dlglevels = v;
    }).finally( () => {
      callback();
    });    
  }

  get_dlg_type(callback: () => void) {
    this.grpcGolfClassTypeLib.getGolfClassTypees({
      Offline: this.storeLib.cache.golfClassType || false,
    }).then( v => {
      this.dlgtypes = v;
    }).finally( () => {
      callback();
    });    
  }


  show_filter() {      

    this.dlgdisplay = 'block';
    this.dlgloading = true;
    
    this.dlgSelect.date = moment();
    this.dlgSelect.setupdays();
    this.step = 1;

    // loading
    this.dlgteachers = [];
    let thise = this;
    this.get_dlg_teachers(() => {
      thise.get_dlg_theme(() => {
        thise.get_dlg_level(() => {
          thise.get_dlg_type(() => {
            thise.dlgloading = false;
          });
        });
      });  
    });
  }


  themename(id: string): string {
    if (!this.dlgthemes) return '';

    let t = this.dlgthemes.filter((t) => t.getId() == id)
    if (!t) return '';

    return t[0].getName();
  }  

  levelname(id: string): string {
    if (!this.dlglevels) return '';

    let t = this.dlglevels.filter((t) => t.getId() == id)
    if (!t) return '';

    return t[0].getName();
  }  

  typename(id: string): string {
    if (!this.dlgtypes) return '';

    let t = this.dlgtypes.filter((t) => t.getId() == id)
    if (!t) return '';

    return t[0].getName();
  }  

  isattend(c: GolfClass, mycls: GolfClass[]) {
    return GolfCardComponent.isattend(c, mycls);
  }

  canattend(c: GolfClass) {
    return GolfCardComponent.canattend(c);
  }

  nextweek(dir: number) {
    this.dlgSelect.date1st.add(5 * dir, 'days');
    this.dlgSelect.setupdays();    
  }  
  dlgsetdate(d: moment.Moment) {
    this.dlgSelect.date = d.clone();
    this.dlgSelect.selectday = this.dlgSelect.date.format('YYYYMMDD');
  }
  close_dlg() {
    this.dlgdisplay = 'none';
    this.dlgloading = false;
  }

  updateFilter() {
    this.classloading = true;
    this.get_dlg_class(() => {

      // only when dlg not open
      if (this.dlgdisplay == 'none') {
          let qid = this.dlgclasses?.filter((e) => e.getId() == this.queryid);
          if (qid?.length > 0) {

              let qd = moment(qid[0].getDate(), 'YYYYMMDD');

              this.showCalendar = false;
              this.dlgSelect.date1st = qd;
              this.dlgSelect.setupdays();    
              this.dlgsetdate(qd);              
              this.gcollapse = this.queryid;
          }
      }

      this.getmylist(() => {
        this.classloading = false;

        this.dlgdisplay = 'none';
        this.dlgloading = false;
    });
  });
  }

  getMonth(d: number) {
    return moment(d + '', 'YYYYMMDD').locale(this.userLocale).format('MMM');
  }
  prevMonth() {
    this.calendarDate = moment(this.calendarDate).subtract(1, 'month').toDate();
  }
  nextMonth() {
    this.calendarDate = moment(this.calendarDate).add(1, 'month').toDate();
  }
  availEvent(d: string) {    
    return this.dlgclassesAvail?.indexOf(d) >= 0;
  }

  fullEvent(d: string) {    
    return this.dlgclassesfull.indexOf(d) >= 0;
  }
  get userLocale(): string {
    return this.userLib.Data.lg || 'en';
  }
  
  calendarDayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.showCalendar = false;
    this.dlgSelect.date1st = moment(date);
    this.dlgSelect.setupdays();    
    this.dlgsetdate(moment(date));
  }

  get me() {
    return this;
  }
}
