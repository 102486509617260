import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { Observable, Subscriber } from 'rxjs';
import { CustomizedMenu, Empty, Link, MenuAlias } from '../../../libs/proto/commUnity_pb';
import * as grpcWeb from 'grpc-web';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { CustomizedMenusConversionService } from '../../conversion/customizemenu/customizemenu-conversion.service';
import { MenuAliasConversionService } from '../../conversion/menuAlias/menuAlias-conversion.service';

@Injectable({
  providedIn: 'root'
})
export class GrpcMenuLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: MenuAliasConversionService,
    private convCustomizedMenuLib: CustomizedMenusConversionService,
  ) { }

  getCustomizedMenus(option?: GrpcLibServiceOption): Promise<CustomizedMenu[]>{
    return new Observable<CustomizedMenu[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getCustomizedMenusOffline(opt);
      } else {
        this._getCustomizedMenusOnline(opt);
      }
    }).toPromise();
  }

  private _getCustomizedMenusOffline(option: GrpcLibServiceOption){
    this.convCustomizedMenuLib.FromStorages(
      (this.storLib.get('menu-s') || this.storLib.get('menu')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getCustomizedMenusOnline(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getCustomizedMenus(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getCustomizedMenusOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getCustomizedMenusOnlineStatus(s, option);
      })
      .on('data', (r: CustomizedMenu) => {
        this._getCustomizedMenusOnlineData(r, option);
      })
      .on('end', () => {
        this._getCustomizedMenusOnlineEnd(option);
      });
  }

  private _getCustomizedMenusOnlineData(ret: CustomizedMenu, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getCustomizedMenusOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getCustomizedMenusOnline(option);
    }, option);
  }

  private _getCustomizedMenusOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getCustomizedMenusOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.customMenu = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'menu', this.convCustomizedMenuLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      if (option.call.treat) return;
      this.grpcLib.treatStatus(s, () => {
        this._getCustomizedMenusOnline(option);
      }, option);
    }
  }

  getMenuAlias(option?: GrpcLibServiceOption): Promise<MenuAlias>{
    return new Observable<MenuAlias>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getMenuAliasOffline(opt);
      } else {
        this._getMenuAliasOnline(opt);
      }
    }).toPromise();
  }

  private _getMenuAliasOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorage(
      (this.storLib.get('menua-s') || this.storLib.get('menua')), (ns, e) => {

        if (ns.toArray().length > 0) {
          option.call.subscribe.next(ns);
          option.call.subscribe.complete();
          return;
        }

        this._getMenuAliasOnline(option);
    });
  }

  private _getMenuAliasOnline(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getMenuAlias(new Empty(), {
        token: t,
      }, () => {})
      .on('error', (e: grpcWeb.RpcError) => {
        this._getMenuAliasOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getMenuAliasOnlineStatus(s, option);
      })
      .on('data', (r: MenuAlias) => {
        this._getMenuAliasOnlineData(r, option);
      })
      .on('end', () => {
        this._getMenuAliasOnlineEnd(option);
      });
  }

  private _getMenuAliasOnlineData(ret: MenuAlias, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getMenuAliasOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getMenuAliasOnline(option);
    }, option);
  }

  private _getMenuAliasOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getMenuAliasOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.menuAlias = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'menua', this.convLib.ToStorage(option.call.data[0])
        );
      }

      option.call.subscribe.next(option.call.data[0]);
      if (option?.callback) { option?.callback(option.call.data[0]); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getMenuAliasOnline(option);
      }, option);
    }
  }
}
