<app-toolbar page="links" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''"></app-toolbar>
<div class="form-list with-bg" [ngStyle]="{'background-image': 'url(' + (backgroundImage | pipeWebp) +')','background-size': backgroundSize}">
<div class="company-logo">
  <div class="logo" [ngStyle]="{'background-image': 'url(' + (logo | pipeWebp) +')'}"></div>
  <h1 [ngStyle]="{'color': textColor }">{{ customerName }}</h1>
</div>

  <div class="menus" *ngIf="menuType===0">
    <ul>
      <li *ngFor="let link of links$ | async as links">
        <div class="bg" [ngStyle]="{'background-color': backgroundColor}"></div>
        <img onerror="this.onerror=null;this.src='assets/images/1x1.png';" *ngIf="link?.getIcon()" src="{{ link?.getIcon() }}" class="icon-menu" />
        <div class="a-contain">
          <a *ngIf="!link.getOpeninside()" [ngStyle]="{'color': textColor}" href="{{ link.getUrl() }}" target="_blank">
            <span>{{ link.getName() }}</span>
          </a>
          <a *ngIf="link.getOpeninside()" [ngStyle]="{'color': textColor}" (click)="open(link.getId())">
            <span>{{ link.getName() }}</span>
          </a>
        </div>
      </li>
    </ul>
  </div>

  <div *ngIf="menuType===1" class="menus-photo">
    <div class="menu-col" *ngFor="let link of links$ | async as links">
      <a *ngIf="!link.getOpeninside()" target="_blank" [href]="link.getUrl()" routerLinkActive="router-link-active">
        <div *ngIf="!link.getOpeninside()" [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + link.getPhoto() }"></div>
          <span [ngStyle]="{'color': textColor}">
          {{ link.getName() }}
        </span>
      </a>
      <a *ngIf="link.getOpeninside()" (click)="open(link.getId())">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + link.getPhoto() }"></div>
          <span [ngStyle]="{'color': textColor}">
          {{ link.getName() }}
        </span>
      </a>
    </div>
  </div>

  <div *ngIf="menuType===2" class="menus-tablet">
    <div class="menu-col" *ngFor="let link of links$ | async as links">
      <a *ngIf="!link.getOpeninside()" target="_blank" [href]="link.getUrl()" routerLinkActive="router-link-active">
        <div class="menu-col-in">
        <div *ngIf="!link.getOpeninside()" [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + link.getPhoto() }"></div>
          <span [ngStyle]="{'color': textColor}">
          {{ link.getName() }}
        </span>
        </div>
      </a>
      <a *ngIf="link.getOpeninside()" (click)="open(link.getId())">
        <div class="menu-col-in">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + link.getPhoto() }"></div>
          <span [ngStyle]="{'color': textColor}">
          {{ link.getName() }}
        </span>
        </div>
      </a>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
