<app-toolbar page="meal-checkout"></app-toolbar>

<div class="form-list" *ngIf="showCheckout">

<div class="order-page">
<div class="order-rows">

<div class="row note">
  <div class="col">
    <div class="color_blue bold">
      <mat-label>{{ 'meal-checkout.note' | translate }}</mat-label>
    </div>
    <mat-form-field class="wtext" appearance="fill">
      <textarea [ngStyle]="{'height': '200px'}" matInput [(ngModel)]="orderForm.note"></textarea>
    </mat-form-field>
  </div>
</div>

<div class="row total">
  <div class="col text-right total-label"
    [innerHTML]="('meal-checkout.total_label' | translate)?.replace('%s', TotalText).replace('%c', currencysymbol)">
  </div>
</div>
</div>
</div>
<div class="bottom">
  <button mat-raised-button
    (disabled)="orderForm.address == undefined"
    (click)="checkOut()"
    [class]="'button-checkout' + (orderForm.address == undefined && orderForm.type === '0' ? ' confirm-disabled': '')">
    {{ 'meal-checkout.button' | translate }}
  </button>
</div>
</div>

<div class="confirm-loading" *ngIf="orderForm.confirmLoading">
  <div class="bg"></div>
  <mat-spinner class="wait"></mat-spinner>
</div>

<app-alcohol
(closeBox)="closeAlcohol()"
*ngIf="hasAlcohol"></app-alcohol>

<div class="confirm-pay" *ngIf="askPay">
  <div class="bg"></div>
  <div class="body">
    <h4>{{ 'meal-payment.title' | translate }}</h4>
    <p>
      {{ 'meal-checkout.confirm_pay' | translate }}
    </p>
    <div class="buttons">
      <ul>
        <li>
          <div>
            <button (click)="pay_online()" mat-raised-button class="cb">
              {{ 'meal-checkout.pay-online' | translate }}
            </button>
            <img src="/assets/images/cb.png" height="36px"/>
          </div>
        </li>
        <li>
          <div>
            <button (click)="pay_waiter()" mat-raised-button color="primary">{{ 'meal-checkout.pay-waiter' | translate }}</button>
            <div class="waiter">
              <img src="/assets/images/waiter.png" height="36px"/>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
