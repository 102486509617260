<app-toolbar page="golf_classes" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''" (evtsortgolf)="changesort($event)"></app-toolbar>

<div class="form-list" style="background-color: lightgray;" [ngClass]="{tablet: menuType==2}" *ngIf="gclasses$ | async as gclasses ">
  <mat-action-list>

    <div style="margin: auto">
      <app-golf-card    
      *ngFor="let gclass of sortlist(gclasses)"
      [c] = "gclass"
      [route]="route"
      [colorlib]="colorlib"
      [userLib]="userLib"
      [grpcGolfClassLib]="grpcGolfClassLib"
      [dlglib]="dlglib"
      [storeLib]="storeLib"
      [readonly]="true"
      [dlgteachers]="dlgteachers"
      [dlgwaitattend]="dlgwaitattend"
      [T]="T"
      [my]="my"
      [dlgdisplay]="dlgdisplay"></app-golf-card>
  
    </div>

  </mat-action-list>
</div>

<ng-template #loading>
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

