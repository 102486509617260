<app-toolbar page="checkout"></app-toolbar>

<div class="form-list">

<div class="order-page">
<div class="order-rows">
<div class="row type" *ngIf="canDelivery && canPickup">
  <div class="col">
    <mat-radio-group class="w-100" (change)="changeType()" color="primary" [(ngModel)]="orderForm.type">
      <mat-radio-button class="w-50 color_blue bold"  value="0">{{ 'checkout.delivery' | translate }}</mat-radio-button>
      <mat-radio-button class="color_blue bold" value="1">{{ 'checkout.pickup' | translate }}</mat-radio-button>
    </mat-radio-group>
  </div>
</div>

<div class="row delivery" *ngIf="isDelvery && canDelay">
  <div class="col color_blue bold">
    <mat-checkbox color="primary" [(ngModel)]="orderForm.delay">
      {{ 'checkout.delay_date' | translate}}
    </mat-checkbox>
    <mat-form-field class="date-delay" *ngIf="orderForm.delay">
      <input readonly [(ngModel)]="orderForm.dateV" (click)="picker.open()" matInput [matDatepicker]="picker">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker ></mat-datepicker>
    </mat-form-field>
  </div>
</div>

<div class="row-h5"></div>
<div class="row pickup" *ngIf="isPickup">
  <div class="col">
    <span class="color_blue bold mt1">{{ 'checkout.pick_message' | translate }}</span>
    <mat-spinner *ngIf="waitPickup"></mat-spinner>
    <p class="under-b" [innerHTML]="orderForm.pickupMsg | pipeDescription">
    </p>
  </div>
</div>

<div class="row address" *ngIf="isDelvery">
  <div class="col">
    <span class="color_blue bold">{{ 'checkout.address' | translate }}</span>
    <button mat-button (click)="addAddress()" class="pl-0">
      <mat-icon>add_circle_outline</mat-icon>
    </button>
  </div>
</div>

<div class="row address-select" *ngIf="isDelvery">
  <div class="col">
    <span class="address-spin">
      <mat-spinner class="address-loading" diameter="16" *ngIf="orderForm.addressLoading"></mat-spinner>
    </span>
    <button (click)="selectAddress()">
      <div>{{ this.orderForm.address?.getName() || ('checkout.address_select' | translate) }}</div>
      <div class="select-add-text">{{ selectAddressText }}</div>
    </button>
  </div>
</div>

<div class="row note">
  <div class="col">
    <div class="color_blue bold">
      <mat-label>{{ 'checkout.note' | translate }}</mat-label>
    </div>
    <mat-form-field class="wtext" appearance="fill">
      <textarea matInput [(ngModel)]="orderForm.note"></textarea>
    </mat-form-field>
  </div>
</div>

<div class="row shipping" *ngIf="isDelvery">
  <div class="col text-right">
    <mat-spinner class="delivery-loading" diameter="16" *ngIf="orderForm.deliveryLoading"></mat-spinner>
    <span>
      {{
        ('checkout.shipping_label' | translate)?.replace('%s',
          orderForm.deliveryPrice == undefined ? '...':
            ((orderForm.deliveryPrice || 0) == 0 ?
            'checkout.shipping_free':
            deliveryCost + ' ' + currencysymbol ) | translate)
      }}
    </span>
  </div>
</div>

<div class="row total">
  <div class="col text-right total-label"
    [innerHTML]="('checkout.total_label' | translate)?.replace('%s', TotalText).replace('%c', currencysymbol)">
  </div>
</div>
</div>
</div>
<div class="bottom">
  <button mat-raised-button
    (disabled)="orderForm.address == undefined"
    (click)="checkOut()"
    [class]="'button-checkout' + (orderForm.address == undefined && orderForm.type === '0' ? ' confirm-disabled': '')">
    {{ 'checkout.button' | translate }}
  </button>
</div>
</div>

<div class="box-select-address" *ngIf="orderForm.addressShow">
  <div class="bg"></div>
  <div class="box-select-address-body">
    <h2 [ngStyle]="{'background-color': backgroundColor, 'color': foregroundColor}">
      {{ 'checkout.address_select' | translate }}
    </h2>
    <div class="box-select-address-content">
      <mat-action-list>
        <mat-list-item #me
          [class]=" orderForm?.address?.getId() === a.getId() ? 'address-selected' : ''"
          (click)="chooseAddress(a)"
          *ngFor="let a of orderForm.addresses">
          <span class="address-name">
            {{ a.getName() }}
          </span>
          <button (click)="deleteAddress($event,a.getId(), me)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-list-item>
      </mat-action-list>
    </div>
  </div>

</div>

<div class="box-add-address" *ngIf="orderForm.addressAdd">
  <div class="bg"></div>
  <div class="box-add-address-body">
    <h2 [ngStyle]="{'background-color': backgroundColor, 'color': foregroundColor}">
      {{ 'add_addr.title' | translate }}
    </h2>
    <div class="box-add-address-content">
      <mat-action-list>
        <mat-list-item class="linew-100">

          <mat-form-field class="w-100" appearance="fill" [floatLabel]="'always'">
            <mat-label>{{ 'add_addr.add_name' | translate }}</mat-label>
            <input matInput required [(ngModel)]="orderForm?.addressForm.name">
          </mat-form-field>

        </mat-list-item>
        <mat-list-item class="linew-100">

          <mat-form-field class="w-100" appearance="fill" [floatLabel]="'always'">
            <mat-label>{{ 'add_addr.add_street' | translate }}</mat-label>
            <input matInput required [(ngModel)]="orderForm?.addressForm.street">
          </mat-form-field>

        </mat-list-item>
        <mat-list-item class="linew-100">

          <mat-form-field class="w-100" appearance="fill" [floatLabel]="'always'">
            <mat-label>{{ 'add_addr.add_city' | translate }}</mat-label>
            <input matInput required [(ngModel)]="orderForm?.addressForm.city">
          </mat-form-field>

        </mat-list-item>
        <mat-list-item class="linew-100">

          <mat-form-field class="w-100" appearance="fill" [floatLabel]="'always'">
            <mat-label>{{ 'add_addr.add_postcode' | translate }}</mat-label>
            <input matInput required [(ngModel)]="orderForm?.addressForm.postcode">
          </mat-form-field>

        </mat-list-item>
        <mat-list-item class="linew-100">

          <mat-form-field class="w-100" appearance="fill" [floatLabel]="'always'">
            <mat-label>{{ 'add_addr.add_phone' | translate }}</mat-label>
            <input matInput required [(ngModel)]="orderForm?.addressForm.phone">
          </mat-form-field>

        </mat-list-item>
      </mat-action-list>
    </div>
    <div class="add-address-bottom">
      <button class="col1" (click)="doAdd()" [class]="canAdd ? '' : 'btn-disable'" [disabled]="!canAdd" mat-raised-button>
          {{ 'add_addr.add_save_button' | translate }}
      </button>
      <button class="col2" (click)="cancelAdd()" mat-raised-button>
          {{ 'add_addr.add_cancel_button' | translate }}
      </button>
    </div>
  </div>

</div>

<div class="confirm-loading" *ngIf="orderForm.confirmLoading">
  <div class="bg"></div>
  <mat-spinner class="wait"></mat-spinner>
</div>
