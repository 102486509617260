<app-toolbar page="news" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''"></app-toolbar>

<div class="form-list" [ngClass]="{tablet: menuType==2}" *ngIf="news$ | async as news ">
  <mat-action-list *ngFor="let new of news">

    <mat-card>
      <div (click)="open(new.getId())"  >
        <mat-card-header>
          <div mat-card-avatar class="img"
            [ngStyle]="{'background-image': 'url(' + new.getImage() + ')'}" >
          </div>
          <mat-card-title> {{ new.getTitle() }}</mat-card-title>
          <mat-card-subtitle>{{ new.getShortdescription() }}</mat-card-subtitle>
          <mat-card-subtitle>{{ new.getPublishdate() | amParse: 'YYYYMMDD' | amDateFormat: dateFormat}}</mat-card-subtitle>
        </mat-card-header>
      </div>
    </mat-card>

  </mat-action-list>
</div>

<ng-template #loading>
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
