import { Component, OnInit, ContentChild, TemplateRef } from '@angular/core';
import { GrpcLibService } from '../../service/grpc/grpc-lib.service';
import { Router } from '@angular/router';
import { UserLibService } from '../../service/user/user-lib.service';
import { Subject, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StorageLibService } from '../../service/storage/storage-lib.service';
import { GrpcPlaceLibService } from '../../service/grpc/place/grpc-place-lib.service';
import { Place } from '../../libs/proto/commUnity_pb';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.sass']
})
export class PlacesComponent implements OnInit {

  places$: Promise<Place[]>;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;

  constructor(
    private route: Router,
    private grpcPlacesLib: GrpcPlaceLibService,
    private userLib: UserLibService,
    private translate: TranslateService,
    private storeLib: StorageLibService,
    private detector: DeviceLibService,
  ) {
    userLib.setPageLanguage( this.translate );
  }

  ngOnInit(): void {
    if (this.storeLib.get('places-s')) {
      this.storeLib.set('places-s', null);
      this.storeLib.cache.places = false;
    }
    this.places$ = this.grpcPlacesLib.getPlace({
      Offline: this.storeLib.cache.places || false,
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }

  open(id: string){
    this.route.navigate(['/place', id]);
  }

  get dateFormat(): string {
    return this.userLib.dateFormat;
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }
}
