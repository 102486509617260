import { Component, OnInit, OnDestroy, HostListener, ElementRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CropperComponent } from 'angular-cropperjs';
import * as moment from 'moment';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Subject, delay } from 'rxjs';
import { desktopMode } from 'src/app/config/type';
import { Title } from 'src/app/libs/proto/commUnity_pb';
import { HotixGuest, HotixGuestResp, HotixIdentityDocumentType, HotixPreCheckin, HotixPreCheckinStatus, HotixReservation, HotixReservationDetail, HotixReservationResp, HotixReservationStatus } from 'src/app/libs/proto/hotix_pb';
import { GuestConversionService } from 'src/app/service/conversion/guest/guest-conversion.service';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { DialogServiceService } from 'src/app/service/dialog/dialog-service.service';
import { GrpcGuestLibService } from 'src/app/service/grpc/guest/grpc-guest-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { UserLibService } from 'src/app/service/user/user-lib.service';

interface CameraBox {
  title?: string;
  showSelector?: boolean;
  showPreview?: boolean;
  showCrop?: boolean;
  showCropper?: boolean;
  showSave?: boolean;
  noCamera?: boolean;
  saving?: boolean;

  base64?: string;
  old64?: string;

  cropMode?: boolean;
  moveMode?: boolean;
  crop64?: string;
  cropped?: boolean;
  cropping?: boolean;
  cropConfig?: any;

  errors?: WebcamInitError[];
  saveError?: string[];

  option?: {
    width: number;
    facing: MediaTrackConstraints;
  };
  event?: {
    webcam: Subject<void>;
  };
}


@Component({
  selector: 'app-guest-precheckin',
  templateUrl: './precheckin.component.html',
  styleUrls: ['./precheckin.component.sass']
})
export class GuestPreCheckinComponent implements OnInit {

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;
  menuType = 0;

  transfer = false;
  showtransfer = false;
  guestno = 0;
  guests: guest[] = [];
  reservation_id = '';
  //docno = 0;
  opendoc = null;

  show_doc = false;
  new_doc = false;
  cameraBox: CameraBox = {};
  selfieBox: CameraBox = {};
  selfie = false;
  tkeys = {};

  waitsave = false;
  errsave = '';
  oksave = false;

  @Output() pictureEvent = new EventEmitter<WebcamImage>();
  @ViewChild('imgorg') imgorg: CropperComponent;
  @ViewChild('selfieorg') selfieorg: CropperComponent;
  @ViewChild('docfile') docfile: ElementRef;
  @ViewChild('selfiefile') selfiefile: ElementRef;
  
  reservation: HotixReservation;
  reservationDetail: HotixReservationDetail;

  doctypes = [];
  
  constructor(
    private route: Router,
    private userLib: UserLibService,
    private detector: DeviceLibService,
    private translate: TranslateService,
    private actRoute: ActivatedRoute,
    private dialogLib: DialogServiceService,
    private grpcLib: GrpcGuestLibService,
    private storeLib: StorageLibService,
    private convLib: GuestConversionService,
  ) {
    this.doctypes = (Object.keys(HotixIdentityDocumentType).map( (v, i) => [HotixIdentityDocumentType[v],v]));
  }
  
  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
  getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4) {
        if (this.isLandscape) { return 2; }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }
  prepareTranslate(callback: ()=>void){
    this.translate.get([
      'guest.precheckin-saved',
    ]).toPromise().then( v => {
      this.tkeys = v;
    }).finally( () => callback() );
  }
  ngOnInit(): void {
    const thise = this;
    this.actRoute.paramMap.subscribe(p => {
      thise.reservation_id = p.get('id');
      this.prepareTranslate( () => {
      thise.grpcLib.getHotixSettings({
        Offline: true,
      }).then( s => {
      if (s && s.length > 0){
        thise.showtransfer = s[0].getRequestpickupatarrival();
      }

      thise.grpcLib.getHotixReservation({
        Offline: true,
      }).then(ns => {
        const dd = ns.filter(n => n.getHotixreservation().getResaid().toString() === thise.reservation_id);
        // not found any
        if (dd.length === 0) {
          thise.route.navigateByUrl('/guest/reservations');
          return;
        }
        thise.reservation = dd[0];

        thise.grpcLib.getHotixReservationDetail({
          Offline: true,
        }).then( dt => {
          const dd = dt.filter(n => n.getReservationid() === thise.reservation.getId());
          if (dd?.length > 0) {
            thise.reservationDetail = dd[0];

            thise.grpcLib.getHotixPreCheckin({
              Offline: this.storeLib.cache.guestPrecheckin || false,
              call:{
                req: thise.reservation.getId(),
              }
            }).then( p => {
              thise.setupreq(p);
            });
          }
        });
      });
      });
      });
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });    
  }

  setupreq(p: HotixPreCheckin[]) {  
    // load first from reservation  
    for(var i=0;i<this.reservation?.getHotixreservation().getGuestsList().length;i++){
      let g = new guest();
      
      let rg = this.reservation?.getHotixreservation().getGuestsList()[i];
      g.firstname = rg?.getFirstname();
      g.lastname = rg?.getLastname();
      if (rg?.getNationality()) g.national = rg?.getNationality();
      if (rg?.getBirthdate()) g.birth_date = moment(rg?.getBirthdate(),'YYYYMMDD');
      g.email = rg?.getEmail();

      let rd = this.reservationDetail?.getHotixreservationdetail();
          
      if (rd?.getPaxdetailsList() && rd?.getPaxdetailsList().length > 0) {
      if (rd?.getPaxdetailsList()[0].getClientfirstname()) g.firstname = rd?.getPaxdetailsList()[0].getClientfirstname();
      if (rd?.getPaxdetailsList()[0].getClientlastname()) g.lastname = rd?.getPaxdetailsList()[0].getClientlastname();
      if (rd?.getPaxdetailsList().length > i + 1) {
      if (rd?.getPaxdetailsList()[i].getClientnationality()) g.national = rd?.getPaxdetailsList()[i].getClientnationality();
      if (rd?.getPaxdetailsList()[i].getClientbirthdate()) g.birth_date = moment(rd?.getPaxdetailsList()[i].getClientbirthdate(),'YYYYMMDD');
      }
      if (rd?.getPaxdetailsList()[0].getClientemail()) g.email = rd?.getPaxdetailsList()[0].getClientemail();
      if (rd?.getPaxdetailsList()[0].getClientaddress()) g.address = rd?.getPaxdetailsList()[0].getClientaddress();
      }

      this.guests.push(g);
    }

    // load from data
    if (p?.length > 0 && p[0].getId()) {
      this.transfer = p[0].getTransfer();
      for(var i=0;i<this.guests.length;i++) {
        this.guests[i].firstname = p[0].getGuestsList()[i].getFirstname();
        this.guests[i].lastname = p[0].getGuestsList()[i].getLastname();
        this.guests[i].national = p[0].getGuestsList()[i].getNationality();
        this.guests[i].birth_date = null;
        if (p[0].getGuestsList()[i].getBirthday()) this.guests[i].birth_date = moment(p[0].getGuestsList()[i].getBirthday(),'YYYYMMDD');
        this.guests[i].email = p[0].getGuestsList()[i].getEmail();
        this.guests[i].address = p[0].getGuestsList()[i].getHomeaddress();
        
        this.guests[i].documents.push(new guest_document());
        this.guests[i].documents[0].photo = p[0].getGuestsList()[i].getIdentitydocument();
        
        this.guests[i].documents[0].date_expire = null;
        this.guests[i].documents[0].date_issue = null;
        if (p[0].getGuestsList()[i].getIdentitydocumentexpdate()) this.guests[i].documents[0].date_expire = moment(p[0].getGuestsList()[i].getIdentitydocumentexpdate(),'YYYYMMDD');
        if (p[0].getGuestsList()[i].getIdentitydocumentissdate()) this.guests[i].documents[0].date_issue = moment(p[0].getGuestsList()[i].getIdentitydocumentissdate(),'YYYYMMDD');
        this.guests[i].documents[0].place_issue = p[0].getGuestsList()[i].getIdentitydocumentissplace();
        this.guests[i].documents[0].id = p[0].getGuestsList()[i].getIdentitydocumentnumber();        
        try {
        this.guests[i].documents[0].type = (this.doctypes.filter((v => v[0] == p[0].getGuestsList()[i].getIdentitydocumenttype())) || [[]])[0][1];
        } catch {}
        this.guests[i].photo = p[0].getGuestsList()[i].getPhoto();
      }
    }

    this.guestno = 1;

    this.setupcamera();
  }
  setupcamera() {
    // setup
    this.cameraBox.moveMode = true;
    this.cameraBox.cropConfig = {
      autoCrop: false,
      zoomable: true,
      dragMode: 'move',
      aspectRatio: 1 / 1,
      movable: true,
      minCanvasWidth: window.innerWidth * 0.8,
      minContainerHeight: window.innerHeight * 0.8,
      crop: (e) => {
        this.cameraBox.cropping =
            (e.detail.width > 0 && e.detail.height > 0);
      }
    };
    this.cameraBox.option = {
      width: window.innerWidth,
      facing: {
        facingMode: 'environment'
      },
    };
    this.cameraBox.event = {
      webcam: new Subject<void>()
    };

    this.selfieBox.moveMode = true;
    this.selfieBox.cropConfig = {
      autoCrop: false,
      zoomable: true,
      dragMode: 'move',
      aspectRatio: 1 / 1,
      movable: true,
      minCanvasWidth: window.innerWidth * 0.8,
      minContainerHeight: window.innerHeight * 0.8,
      crop: (e) => {
        this.selfieBox.cropping =
            (e.detail.width > 0 && e.detail.height > 0);
      }
    };
    this.selfieBox.option = {
      width: window.innerWidth,
      facing: {
        facingMode: 'user'
      },
    };
    this.selfieBox.event = {
      webcam: new Subject<void>()
    };
  }
  guest_precheck_add(e: any) {
    this.guests.push(new guest());
    this.guestno = this.guests.length;
  }
  removeguest() {
    let thise = this;
    this.translate.get([
      'guest.precheckin-guest-confirm-remove',
      'Login.err_refer'
    ]).toPromise().then( t => {
      this.dialogLib.confirm(
        t['guest.precheckin-guest-confirm-remove'].replace('%s', this.currentguest.name),
        r => {
        if (r.yes){
          thise.guests.splice(this.guestno - 1, 1);
          //thise.docno = thise.currentguest.documents.length;
        }
      });
    });
  }
  get hasprevguest() {
    return this.guestno > 1;
  }
  get hasnextguest() {
    return this.guestno < this.guests.length;
  }
  prevguest(){
    this.guestno -= 1;
  }
  nextguest() {
    this.guestno += 1;
  }

  openPhotoSelector() {
    this.selfie =false;
    this.docfile.nativeElement.click();
  }

  docChange(e: any) {
    const targetSize = 320;

    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (ev: any) => {
        if (e.target.files[0].type == 'application/pdf') {
          this.cameraBox.base64 = ev.target.result;
          this.opendoc.newphoto = 'pdf';
        } else {
          const image = new Image();
          image.onload = () => {

            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            // calculated to max size
            // 320px
            let isizew = image.width;
            let isizeh = image.height;
            if (isizew > isizeh) {
              // landscape
              if (isizew > targetSize) {
                isizew = targetSize;
                isizeh = image.height / image.width * targetSize;
              }
            } else if (isizeh > isizew) {
              // portrait
              if (isizeh > targetSize) {
                isizeh = targetSize;
                isizew = image.width / image.height * targetSize;
              }
            }

            canvas.width = isizew;
            canvas.height = isizeh;
            context.drawImage(image,
                0,
                0,
                image.width,
                image.height,
                0,
                0,
                canvas.width,
                canvas.height
            );
            const c = canvas.toDataURL();
            if (c.length < 100) {
              return;
            }
            
            if (this.selfie) {
              this.updatecropimg(this.selfieBox, this.selfiefile, c);
            } else {
              this.updatecropimg(this.cameraBox, this.docfile, c);
            }          
          };
          image.src = ev.target.result;
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }
  private updatecropimg(b: CameraBox, file: ElementRef<any>,c?: string) {
    b.base64 = c;
    b.old64 = b.base64;
    b.showPreview = true;
    b.showCrop = true;

    file.nativeElement.value = '';
  }

  adddoc() {
    this.opendoc = new guest_document();
    this.show_doc = true;   
    this.new_doc = true;
    this.closeCameraBox();
    //this.currentguest.documents.push(new guest_document());
    //this.docno += 1;
  }
  canceldoc() {
    this.show_doc = false;
    this.opendoc = null;
  }
  savedoc() {
    this.opendoc.photo = this.opendoc.newphoto == 'pdf' ? this.cameraBox.base64 ?? this.opendoc.photo : this.opendoc.newphoto;
    if (this.new_doc) {
       this.currentguest.documents.push(this.opendoc);
    }
    this.new_doc = false;
    this.opendoc = null;
    //this.docno += 1;
    this.show_doc = false;
  }
  removedoc(doc: guest_document, index: number) {
    let thise = this;
    this.translate.get([
      'guest.precheckin-doc-confirm-remove',
      'Login.err_refer'
    ]).toPromise().then( t => {
      this.dialogLib.confirm(
        t['guest.precheckin-doc-confirm-remove'].replace('%s', doc.id),
        r => {
        if (r.yes){
          thise.currentguest.documents.splice(index, 1);
          //thise.docno = thise.currentguest.documents.length;
        }
      });
    });
  }
  editdoc(index: number) {
    this.opendoc = this.currentguest.documents[index];    
    this.opendoc.newphoto = (this.opendoc.photo.indexOf('.pdf') >=0) || (this.opendoc.photo.indexOf('application/pdf') >=0) ? 'pdf' : this.opendoc.photo;
    this.show_doc = true;
    this.closeCameraBox();
  }

  handleWebcam(webcamImage: WebcamImage): void {
    this.pictureEvent.emit(webcamImage);
    if (this.selfie) {
      this.handlewebcam_box(this.selfieBox, webcamImage);
    } else {
      this.handlewebcam_box(this.cameraBox, webcamImage);
    }
  }

  private handlewebcam_box(b: CameraBox, webcamImage: WebcamImage) {
    b.showCrop = true;
    b.old64 = webcamImage.imageAsDataUrl;
    b.base64 = webcamImage.imageAsDataUrl;
  }

  handleWebcamError(error: WebcamInitError): void {
    if (this.selfie) {
      this.handleWebcamError_box(this.selfieBox, error);
    } else {
      this.handleWebcamError_box(this.cameraBox, error);
    }
  }
  private handleWebcamError_box(b: CameraBox, error: WebcamInitError): void {    
    if (b.errors === undefined) {
      b.errors = [];
    }

    if (error.message.indexOf('Requested device not found') >= 0) {
      b.noCamera = true;
    }
    b.errors.push(error);
  }

  private closeCameraBox() {
    if (this.selfie) {
      this.closecamerabox_box(this.selfieBox);
    } else {
      this.closecamerabox_box(this.cameraBox);
    }
  }
  private closecamerabox_box(b: CameraBox) {
    b.title = undefined;
    b.base64 = undefined;
    b.crop64 = undefined;
    b.cropped = undefined;
    b.errors = undefined;
    b.old64 = undefined;
    b.showCrop = undefined;
    b.showPreview = undefined;
    b.showSave = undefined;
    b.saveError = undefined;
    b.saving = undefined;
    b.showCropper = undefined;
    b.cropping = undefined;

    b.cropMode = false;
    b.moveMode = true;

  }
  cancelCamera() {
    this.closeCameraBox();
  }
  okCrop() {
    if (this.selfie) {
      this.okCrop_box(this.selfieBox, this.selfieorg);      
    } else {
      this.okCrop_box(this.cameraBox, this.imgorg);
    }
  }
  private okCrop_box(b: CameraBox, org: CropperComponent) {
    b.base64 = org.cropper.getCroppedCanvas().toDataURL();
    b.cropped = true;
    b.cropping = false;
    b.cropMode = false;
    b.moveMode = true;
    org.cropper.clear();  
  }
  closeSave() {
    if (this.selfie) {
      this.closeSave_box(this.selfieBox);
    } else {
      this.closeSave_box(this.cameraBox);
    }
  }
  private closeSave_box(b: CameraBox) {
    b.saving = false;
    b.showSave = false;
  }
  cropImage() {
    if (this.selfie) {
      this.cropImage_box(this.selfieBox, this.selfieorg);
    } else {
      this.cropImage_box(this.cameraBox, this.imgorg);
    }
  }
  private cropImage_box(b: CameraBox, org: CropperComponent) {
    org.cropper.setDragMode('crop');
    b.cropMode = true;
    b.moveMode = false;
  }
  moveImage(){
    if (this.selfie) {
      this.moveImage_box(this.selfieBox, this.selfieorg);
    } else {
      this.moveImage_box(this.cameraBox, this.imgorg);
    }
  }
  private moveImage_box(b: CameraBox, org: CropperComponent) {
    org.cropper.setDragMode('move');
    b.moveMode = true;
    b.cropMode = false;
  }
  zoomIn() {
    if (this.selfie) {
      this.zoomIn_box( this.selfieorg);
    } else {
      this.zoomIn_box( this.imgorg);
    }
  }
  private zoomIn_box(org: CropperComponent) {
    org.cropper.zoom(0.1);
  }
  zoomOut() {
    if (this.selfie) {
      this.zoomOut_box( this.selfieorg);
    } else {
      this.zoomOut_box( this.imgorg);
    }
  }
  private zoomOut_box(org: CropperComponent) {
    org.cropper.zoom(-0.1);
  }
  rotateLeft() {
    if (this.selfie) {
      this.rotateLeft_box( this.selfieorg);
    } else {
      this.rotateLeft_box( this.imgorg);
    }
  }
  private rotateLeft_box(org: CropperComponent) {
    org.cropper.rotate(-90);
  }
  rotateRight() {
    if (this.selfie) {
      this.rotateRight_box( this.selfieorg);
    } else {
      this.rotateRight_box( this.imgorg);
    }
  }
  private rotateRight_box(org: CropperComponent) {
    org.cropper.rotate(90);
  }
  cancelCrop() {
    if (this.selfie) {
      this.cancelCrop_box(this.selfieBox, this.selfieorg);
    } else {
      this.cancelCrop_box(this.cameraBox, this.imgorg);
    }  }
  private cancelCrop_box(b: CameraBox, org: CropperComponent) {
    org.cropper.clear();
    b.cropping = false;
  }
  cancelPreview() {
    this.closeCameraBox();
  }
  saveImage() {
    if (this.selfie) {
      this.selfieBox.showSave = true;
      this.selfieBox.saving = true;
      
      this.doSaveSelfie();
    } else {
      this.cameraBox.showSave = true;
      this.cameraBox.saving = true;

      this.doSaveImage();
    }
  }
  private doSaveSelfie() {
    if (this.selfieBox.base64) {
      this.currentguest.photo = this.selfieBox.base64;
    }

    this.closeCameraBox();
    this.selfieBox.showSelector = false;
    this.selfieBox.saving = false;

  }
  private doSaveImage() {
    //this.grpcLib.updateProfile(
    //  this.toMemberProfileRequest()
    //).then( m => {
    //  let uu = m.getPhoto().indexOf('?');
    //  if (uu < 0) { uu = undefined; }
    //  this.newPhoto = m.getPhoto().substring(0, uu);

    //  // update in user profile's cache
    //  this.userLib.Data.token?.getProfile().setPhoto(this.newPhoto);
    //  this.userLib.updateUserData();

      if (this.cameraBox.base64) {
        this.opendoc.newphoto = this.cameraBox.base64;
      }

      this.closeCameraBox();
      this.cameraBox.showSelector = false;
    //}).catch( e => {
    //  const er = ['photo.save_error'];
    //  if (!environment.production && JSON.stringify(e) !== '{}') {
    //    er.push( JSON.stringify(e));
    //  }
    //  this.cameraBox.saving = false;
    //  this.cameraBox.saveError = er;
    //}).finally( () => {
      this.cameraBox.saving = false;
    //});
  }
  get currentguest(): guest {
    return this.guests[this.guestno - 1];
  }
  get photo_suffix() {
    return '?ts=' + moment().unix();
  }
  addselfie() {
    this.selfie = true;
    this.closeCameraBox();
    this.selfieBox.showPreview = true;
    //this.selfiefile.nativeElement.click();
  }

  savecheckin(status?: number) {        
    this.waitsave = true;
    let r = new HotixPreCheckin(); 
    let rr = this.reservation.getHotixreservation();
    let rd = this.reservationDetail.getHotixreservationdetail();

    r.setArrivaldate(rd.getArrivaldate());
    r.setDeparturedate(rd.getDeparturedate());
    //r.setFlightnumber(rd.get)
    //r.setId
    r.setMemberid(this.reservation.getMemberid());
    //r.setNumpeople(rd.getnum)
    r.setReservationid(this.reservation.getId());
    r.setRoomtype(rd.getRoomtype());
    r.setStatus(status || HotixPreCheckinStatus.HOTIXPRECHECKINSTATUS_DRAFT);
    //r.setStayreason(rr.reas)    
    r.setTransfer(this.transfer);

    let gs: HotixGuest[] = [];
    for(var i=0;i<this.guests.length;i++) {
      gs.push(this.guests[i].toGuest());
    }
    r.setGuestsList(gs);
    
    setTimeout(() => {
      this.grpcLib.submitHotixPreCheckin(r).then( (v) => {
        this.dialogLib.show(this.tkeys['guest.precheckin-saved']);
      }).catch((e) => { 
        this.dialogLib.show(e);
      }).finally(() => {
        this.waitsave = false;
      });        
    }, 500);
  }
  submitcheckin() {
    this.savecheckin(HotixPreCheckinStatus.HOTIXPRECHECKINSTATUS_CONFIRMED);
  }
  doctypeToEnum(s: string) {
    return HotixIdentityDocumentType[s];
  }
}

class guest {
  job = '';
  lastname = '';
  firstname = '';
  national = '';
  birth_date = null;
  email = '';
  address = '';

  documents:guest_document[] = [];

  photo = '';

  get name() {
    return [this.firstname, this.lastname].join(' ');
  }
  toGuest(): HotixGuest {
    let r = new HotixGuest();

    r.setBirthday(this.birth_date.format('YYYYMMDD'));
    r.setEmail(this.email);
    r.setFirstname(this.firstname);
    r.setHomeaddress(this.address);
    r.setNationality(this.national);    
    r.setLastname(this.lastname);
    //r.setMobile(this.)
    r.setPhoto( this.returnphoto(this.photo));

    if (this.documents.length > 0) {
      r.setIdentitydocument(this.returnphoto(this.documents[0].photo));      
      r.setIdentitydocumentexpdate(this.documents[0].date_expire_value);
      r.setIdentitydocumentissdate(this.documents[0].date_issue_value);
      r.setIdentitydocumentissplace(this.documents[0].place_issue);
      r.setIdentitydocumentnumber(this.documents[0].id);
      r.setIdentitydocumenttype(HotixIdentityDocumentType[this.documents[0].type]);
    }

    return r;
  }
  returnphoto(u: string): string {
    if (u.substring(0, 4) == 'http') {
      return u;
    } else {
      let i = u.indexOf('base64');
      return u.substr(i + 'base64,'.length);      
    }
  }
}
class guest_document { 
  photo = '';
  newphoto = '';
  id = '';

  date_issue = null;
  date_expire = null;
  place_issue = '';
  type = '';

  get date_issue_value() {
    if (!this.date_issue) return 0;
    return parseFloat(this.date_issue.format('YYYYMMDD'));
  }
  get date_expire_value() {
    if (!this.date_expire) return 0;
    return parseFloat(this.date_expire.format('YYYYMMDD'));
  }
}