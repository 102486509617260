<app-toolbar page="guest-precheckin" [data]="reservation_id" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''" ></app-toolbar>

<div class="form-list" [ngClass]="{'pop': selfieBox.showPreview || show_doc || cameraBox.showPreview}" *ngIf="menuType!=2">    
    <mat-card class="guest-card" [ngClass]="{tablet: menuType==2}">
        <mat-card-content>    
            <mat-list class="w-100 t-center">
                {{ 'guest.precheckin-title' | translate}}
            </mat-list>

            <mat-list class="w-100 t-center">              
              <mat-list-item *ngIf="showtransfer">
                <label>{{ 'guest.precheckin-transfer' | translate}}</label>
              &nbsp;
              <mat-checkbox 
              [(ngModel)]="transfer" [ngModelOptions]="{standalone: true}"
                class=""></mat-checkbox>
              </mat-list-item>
            </mat-list>

            <mat-list class="w-100 t-right" *ngIf="guests.length > 0">
                <div class="topbar">
                    <span>
                    <button *ngIf="guests.length > 1" mat-icon-button [disabled]="!hasprevguest" (click)="prevguest()"><mat-icon>chevron_left</mat-icon></button>
                    <button *ngIf="guests.length > 1" mat-icon-button [disabled]="!hasnextguest" (click)="nextguest()"><mat-icon>chevron_right</mat-icon></button>
                    </span>
                    <span>
                    {{ ('guest.precheckin-guest-num' | translate).replace('%1', guestno).replace('%2', guests.length) }}
                    </span>
                </div>
            </mat-list>

            <mat-list class="w-100" *ngIf="currentguest">

                <mat-list-item>
                  <mat-form-field class="w-100">
                    <input matInput id="txt-fname" required="true"
                      placeholder="{{ 'guest.precheckin-fname' | translate }}" [(ngModel)]="currentguest.firstname" [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                </mat-list-item>
  
                <mat-list-item>
                  <mat-form-field class="w-100">
                    <input matInput id="txt-name" required="true"
                      placeholder="{{ 'guest.precheckin-name' | translate }}" [(ngModel)]="currentguest.lastname" [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                </mat-list-item>

                <mat-list-item>
                    <mat-form-field class="w-100">
                    <input matInput id="txt-nation" required="true"
                      placeholder="{{ 'guest.precheckin-nation' | translate }}" [(ngModel)]="currentguest.national" [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                </mat-list-item>

                <mat-list-item>
                    <mat-form-field class="w-100">
                    <input matInput id="txt-birthday" required="true"
                      autocomplete="off"                      
                      [matDatepicker]="picker"          
                      placeholder="{{ 'guest.precheckin-birthday' | translate }}" [(ngModel)]="currentguest.birth_date" [ngModelOptions]="{standalone: true}">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>                      
                  </mat-form-field>
                </mat-list-item>

                <mat-list-item>
                    <mat-form-field class="w-100">
                    <input matInput id="txt-email" required="true"
                      placeholder="{{ 'guest.precheckin-email' | translate }}" [(ngModel)]="currentguest.email" [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                </mat-list-item>

                <mat-list-item>
                    <mat-form-field class="w-100">
                    <input matInput id="txt-address" required="true"
                      placeholder="{{ 'guest.precheckin-address' | translate }}" [(ngModel)]="currentguest.address" [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                </mat-list-item>

                <mat-list-item>
                    <div class="w-100 upload-bar">
                        <label>{{ 'guest.precheckin-document' | translate }}{{ ('guest.precheckin-doc-num' | translate).replace('%1', currentguest.documents.length) }}</label>  
                        <button *ngIf="currentguest.documents.length == 0" (click)="adddoc()"  mat-icon-button><mat-icon>add</mat-icon></button>                  
                    </div>
                </mat-list-item>
                <div class="doc-list" *ngIf="currentguest.documents.length > 0">
                  <div class="doc-list-box">
                  <mat-card class="doc-card" *ngFor="let d of currentguest.documents; index as i">
                  <ng-container [ngTemplateOutlet]="doccard" 
                  [ngTemplateOutletContext]="{doc: d,index: i}">
                  </ng-container>
                  </mat-card>
                  </div>
                </div>

                <mat-list-item>
                  <label>{{ 'guest.precheckin-photo'  | translate }}</label>  
                </mat-list-item>
                
                <div *ngIf="!currentguest.photo" class="selfie-buttons">
                  <button (click)="addselfie()" mat-icon-button><mat-icon>camera</mat-icon></button>
                  <input #selfiefile hidden (change)="docChange($event)" type="file" accept="image/">
                </div>

                <div *ngIf="currentguest.photo"
                  [ngStyle]="{'background-image': 'url(' + currentguest.photo + ')'}"
                  class="doc-image">
                  <button (click)="currentguest.photo = ''" class="change-photo" mat-icon-button><mat-icon>delete</mat-icon></button>
              </div> 
    
              </mat-list>
        </mat-card-content>
    </mat-card>
    <div class="guest-buttons">
      <button mat-raised-button (click)="savecheckin()" color="">{{ 'guest.draft-button' | translate }}</button>
      <button mat-raised-button (click)="submitcheckin()" color="primary">{{ 'guest.submit-button' | translate }}</button>
    </div>
</div>

<div class="mode-tablet" *ngIf="menuType==2">
  <div class="left">
    <mat-card>
    <mat-card-header class="header">
      <mat-card-title [innerHTML]="('guest.reservation-detail-title' | translate).replace('%s', '' )"></mat-card-title>
      <mat-card-subtitle ><BR/>{{ reservation?.getHotixreservation().getResaid() }}</mat-card-subtitle>        
      <mat-card-subtitle ><BR/>{{ 'guest.precheckin-title' | translate }}</mat-card-subtitle>
      
    </mat-card-header>
    <mat-list>              
      <mat-list-item *ngIf="showtransfer">
        <label>{{ 'guest.precheckin-transfer' | translate}}</label>
      &nbsp;
      <mat-checkbox 
      [(ngModel)]="transfer" [ngModelOptions]="{standalone: true}"
        class=""></mat-checkbox>
      </mat-list-item>
    </mat-list>
    </mat-card>
  </div>
  
  <div class="right">
    <div class="body">
      <mat-list class="t-right" *ngIf="guests.length > 0">
        <div class="topbar">
            <span>
            <button *ngIf="guests.length > 1" mat-icon-button [disabled]="!hasprevguest" (click)="prevguest()"><mat-icon>chevron_left</mat-icon></button>
            <button *ngIf="guests.length > 1" mat-icon-button [disabled]="!hasnextguest" (click)="nextguest()"><mat-icon>chevron_right</mat-icon></button>
            </span>
            <span>
            {{ ('guest.precheckin-guest-num' | translate).replace('%1', guestno).replace('%2', guests.length) }}
            </span>
        </div>
    </mat-list>

    <mat-list class="" *ngIf="currentguest">

        <mat-list-item>
          <mat-form-field class="w-100">
            <input matInput id="txt-fname" required="true"
              placeholder="{{ 'guest.precheckin-fname' | translate }}" [(ngModel)]="currentguest.firstname" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </mat-list-item>

        <mat-list-item>
          <mat-form-field class="w-100">
            <input matInput id="txt-name" required="true"
              placeholder="{{ 'guest.precheckin-name' | translate }}" [(ngModel)]="currentguest.lastname" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </mat-list-item>

        <mat-list-item>
            <mat-form-field class="w-100">
            <input matInput id="txt-nation" required="true"
              placeholder="{{ 'guest.precheckin-nation' | translate }}" [(ngModel)]="currentguest.national" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </mat-list-item>

        <mat-list-item>
            <mat-form-field class="w-100">
            <input matInput id="txt-birthday" required="true"
              autocomplete="off"                      
              [matDatepicker]="picker"          
              placeholder="{{ 'guest.precheckin-birthday' | translate }}" [(ngModel)]="currentguest.birth_date" [ngModelOptions]="{standalone: true}">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>                      
          </mat-form-field>
        </mat-list-item>

        <mat-list-item>
            <mat-form-field class="w-100">
            <input matInput id="txt-email" required="true"
              placeholder="{{ 'guest.precheckin-email' | translate }}" [(ngModel)]="currentguest.email" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </mat-list-item>

        <mat-list-item>
            <mat-form-field class="w-100">
            <input matInput id="txt-address" required="true"
              placeholder="{{ 'guest.precheckin-address' | translate }}" [(ngModel)]="currentguest.address" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </mat-list-item>

        <mat-list-item>
            <div class="w-100 upload-bar">
                <label>{{ 'guest.precheckin-document' | translate }}{{ ('guest.precheckin-doc-num' | translate).replace('%1', currentguest.documents.length) }}</label>  
                <button *ngIf="currentguest.documents.length == 0" (click)="adddoc()"  mat-icon-button><mat-icon>add</mat-icon></button>                  
            </div>
        </mat-list-item>
        <div class="doc-list" *ngIf="currentguest.documents.length > 0">
          <div class="doc-list-box">
          <mat-card class="doc-card" *ngFor="let d of currentguest.documents; index as i">
          <ng-container [ngTemplateOutlet]="doccard" 
          [ngTemplateOutletContext]="{doc: d,index: i}">
          </ng-container>
          </mat-card>
          </div>
        </div>

        <mat-list-item>
          <label>{{ 'guest.precheckin-photo'  | translate }}</label>  
        </mat-list-item>
        
        <div *ngIf="!currentguest.photo" class="selfie-buttons">
          <button (click)="addselfie()" mat-icon-button><mat-icon>camera</mat-icon></button>
          <input #selfiefile hidden (change)="docChange($event)" type="file" accept="image/">
        </div>

        <div *ngIf="currentguest.photo"
          [ngStyle]="{'background-image': 'url(' + currentguest.photo + ')'}"
          class="doc-image">
          <button (click)="currentguest.photo = ''" class="change-photo" mat-icon-button><mat-icon>delete</mat-icon></button>
      </div> 

      </mat-list>
      <div class="guest-buttons">
        <button mat-raised-button (click)="savecheckin()" color="">{{ 'guest.draft-button' | translate }}</button>
        <button mat-raised-button (click)="submitcheckin()" color="primary">{{ 'guest.submit-button' | translate }}</button>
      </div>
  
    </div>
  </div>
</div>


<ng-template 
    let-doc="doc"
    let-index="index"
    #doccard>
    <mat-card-content> 
      <div class="content">
        {{ 'guest.precheckin-doc-type' | translate }} : {{ ('HOTIXIDENTITYDOCUMENTTYPE.' + doctypeToEnum(doc.type)) | translate }}<br/>
        {{ 'guest.precheckin-doc-id' | translate }} : {{ doc.id }}<br/>
        {{ 'guest.precheckin-doc-date' | translate }} : {{ doc.date_issue  | amDateFormat: 'DD/MM/YYYY' }}<br/>
        {{ 'guest.precheckin-doc-expire' | translate }} : {{ doc.date_expire | amDateFormat: 'DD/MM/YYYY' }}<br/>
        {{ 'guest.precheckin-doc-by' | translate }} : {{ doc.place_issue }}
      </div>
      <div class="doc-buttons">
        <button mat-icon-button (click)="editdoc(index)"><mat-icon>edit</mat-icon></button>
        <button mat-icon-button (click)="removedoc(doc, index)"><mat-icon>delete</mat-icon></button>        
      </div>
    </mat-card-content>
  
</ng-template>

<div class="modal-popup upload-doc" *ngIf="show_doc">
    <div class="bg"></div>
    <div class="dlg">
        <div class="camera_gallery" *ngIf="!opendoc.newphoto">
            <button style="margin-left: 10px" (click)="openPhotoSelector()" mat-icon-button>
              <mat-icon>upload_file</mat-icon>
            </button>
            <input #docfile hidden (change)="docChange($event)" type="file" accept="image/*,application/pdf">
        </div> 
  
        <div *ngIf="this.opendoc.newphoto && this.opendoc.newphoto != 'pdf'"
            [ngStyle]="{'background-image': 'url(' + opendoc.newphoto + ')'}"
            class="doc-image">
            <button (click)="opendoc.newphoto = ''" class="change-photo" mat-icon-button><mat-icon>delete</mat-icon></button>
        </div> 

        <div *ngIf="this.opendoc.newphoto && this.opendoc.newphoto == 'pdf'"
            class="doc-image">
            <img src="assets/images/pdf.png"/>
            <button (click)="opendoc.newphoto = ''" class="change-photo" mat-icon-button><mat-icon>delete</mat-icon></button>
        </div> 

        <div class="doc-body">
          <mat-card class="doc-card-body" [ngClass]="{tablet: menuType==2}">
            <mat-card-content>    

              <mat-list class="w-100">
                <mat-form-field class="w-100">
                  <mat-select 
                  placeholder="{{ 'guest.precheckin-doc-type' | translate}}"
                  matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="opendoc.type">
                    <mat-option *ngFor="let t of doctypes" [value]="t[1]" >{{ 'HOTIXIDENTITYDOCUMENTTYPE.' + t[0] | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-list>

              <mat-list class="w-100">
                  <mat-form-field class="w-100">
                    <input matInput id="txt-docid" required="true"
                      placeholder="{{ 'guest.precheckin-doc-id' | translate }}" [(ngModel)]="opendoc.id" [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                </mat-list>

                <mat-list class="w-100">
                  <mat-form-field class="w-100">
                    <input matInput id="txt-docdate" required="true"
                    autocomplete="off"                      
                    [matDatepicker]="pickerd"          
                    placeholder="{{ 'guest.precheckin-doc-date' | translate }}" [(ngModel)]="opendoc.date_issue" [ngModelOptions]="{standalone: true}">
                    <mat-datepicker-toggle matSuffix [for]="pickerd"></mat-datepicker-toggle>
                    <mat-datepicker #pickerd></mat-datepicker>                      
                    </mat-form-field>
                </mat-list>

                <mat-list class="w-100">
                  <mat-form-field class="w-100">
                    <input matInput id="txt-docexpire" required="true"
                    autocomplete="off"                      
                    [matDatepicker]="pickere"          
                      placeholder="{{ 'guest.precheckin-doc-expire' | translate }}" [(ngModel)]="opendoc.date_expire" [ngModelOptions]="{standalone: true}">
                    <mat-datepicker-toggle matSuffix [for]="pickere"></mat-datepicker-toggle>
                    <mat-datepicker #pickere></mat-datepicker>                      
                  </mat-form-field>
                </mat-list>

                <mat-list class="w-100">
                  <mat-form-field class="w-100">
                    <input matInput id="txt-docby" required="true"
                      placeholder="{{ 'guest.precheckin-doc-by' | translate }}" [(ngModel)]="opendoc.place_issue" [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                </mat-list>
              </mat-card-content>
          </mat-card>  
        </div>    
        <div class="buttons">

          <button color=""
            (click)="canceldoc()" mat-raised-button>
            {{ 'Dialog.Cancel' | translate }}</button>
    
          <button color="primary"
            (click)="savedoc()" mat-raised-button>
            {{ 'photo.save' | translate }}</button>
        </div>   
    </div>    
</div>

<div #previewCamera class="modal-popup"
  *ngIf="cameraBox.showPreview">
  <div class="bg"></div>
  <div class="dlg-center">
    <div *ngIf="!cameraBox.showCrop" class="box-preview">
      <webcam
        [width]="cameraBox.option.width"
        [trigger]="cameraBox.event.webcam.asObservable()"
        (imageCapture)="handleWebcam($event)"
        [allowCameraSwitch]="false"
        [videoOptions]="cameraBox.option.facing"
        (initError)="handleWebcamError($event)">
      </webcam>

      <div class="buttons">
        <button color="primary"
        (click)="cameraBox.event.webcam.next()" mat-raised-button>📸</button>

        <button color="primary"
        (click)="cancelCamera()" mat-raised-button>
        {{ 'Dialog.Cancel' | translate }}</button>
      </div>
    </div>

    <div *ngIf="cameraBox.showCrop"
      class="box-crop">
      <mat-toolbar [color]="'primary'">
        <mat-toolbar-row class="crop-tool">
          <div class="tool-button">
            <button
                [disabled]="cameraBox.cropping"
                [ngClass]="{'active': cameraBox.moveMode}"
                (click)="moveImage()"
                mat-icon-button>
              <mat-icon>open_with</mat-icon>
            </button>
          </div>
          <div class="tool-button">
            <button
              [ngClass]="{'active': cameraBox.cropMode}"
              (click)="cropImage()"
              mat-icon-button>
              <mat-icon>crop</mat-icon>
            </button>
          </div>
          <div class="tool-button">
            <button
                (click)="zoomIn()"
                mat-icon-button>
              <mat-icon>zoom_in</mat-icon>
            </button>
          </div>
          <div class="tool-button">
            <button
                (click)="zoomOut()"
                mat-icon-button>
              <mat-icon>zoom_out</mat-icon>
            </button>
          </div>
          <div
            class="tool-button">
            <button
                (click)="rotateLeft()"
                mat-icon-button>
              <mat-icon>rotate_left</mat-icon>
            </button>
          </div>
          <div
            class="tool-button">
            <button
                (click)="rotateRight()"
                mat-icon-button>
              <mat-icon>rotate_right</mat-icon>
            </button>
          </div>
          <span class="space"></span>
          <div
            *ngIf="cameraBox.cropping"
            class="tool-button">
            <button
                (click)="cancelCrop()"
                mat-icon-button>
              <mat-icon>undo</mat-icon>
            </button>
          </div>
          <div
          *ngIf="cameraBox.cropping"
            class="tool-button">
            <button
                (click)="okCrop()"
                mat-icon-button>
                <mat-icon>done</mat-icon>
              </button>
            </div>
        </mat-toolbar-row>
      </mat-toolbar>

      <div
        class="crop-container">

      <angular-cropper
        class="img-origin box-crop"
        [cropperOptions]="cameraBox.cropConfig"
        [imageUrl]="cameraBox.base64"
        #imgorg></angular-cropper>
    </div>

    <div class="buttons">

      <button color="warn"
        (click)="cancelPreview()" mat-raised-button>
        {{ 'Dialog.Cancel' | translate }}</button>

      <button color="primary"
        (click)="saveImage()" mat-raised-button>
        {{ 'photo.save' | translate }}</button>
    </div>
</div>
</div>
</div>

<div #selfieCamera class="modal-popup"
  *ngIf="selfieBox.showPreview">
  <div class="bg"></div>
  <div [ngClass]="{'dlg-center': selfieBox.showCrop,'dlg-left': selfieBox.showCrop}">
    <div *ngIf="!selfieBox.showCrop" class="selfie-preview">
      <webcam
        [width]="selfieBox.option.width"
        [trigger]="selfieBox.event.webcam.asObservable()"
        (imageCapture)="handleWebcam($event)"
        [allowCameraSwitch]="false"
        [videoOptions]="selfieBox.option.facing"
        (initError)="handleWebcamError($event)">
      </webcam>

      <div class="buttons">
        <button color="primary"
        (click)="selfieBox.event.webcam.next()" mat-raised-button>📸</button>

        <button color="primary"
        (click)="cancelCamera()" mat-raised-button>
        {{ 'Dialog.Cancel' | translate }}</button>
      </div>
    </div>

    <div *ngIf="selfieBox.showCrop"
      class="box-crop">
      <mat-toolbar [color]="'primary'">
        <mat-toolbar-row class="crop-tool">
          <div class="tool-button">
            <button
                [disabled]="selfieBox.cropping"
                [ngClass]="{'active': selfieBox.moveMode}"
                (click)="moveImage()"
                mat-icon-button>
              <mat-icon>open_with</mat-icon>
            </button>
          </div>
          <div class="tool-button">
            <button
              [ngClass]="{'active': selfieBox.cropMode}"
              (click)="cropImage()"
              mat-icon-button>
              <mat-icon>crop</mat-icon>
            </button>
          </div>
          <div class="tool-button">
            <button
                (click)="zoomIn()"
                mat-icon-button>
              <mat-icon>zoom_in</mat-icon>
            </button>
          </div>
          <div class="tool-button">
            <button
                (click)="zoomOut()"
                mat-icon-button>
              <mat-icon>zoom_out</mat-icon>
            </button>
          </div>
          <div
            class="tool-button">
            <button
                (click)="rotateLeft()"
                mat-icon-button>
              <mat-icon>rotate_left</mat-icon>
            </button>
          </div>
          <div
            class="tool-button">
            <button
                (click)="rotateRight()"
                mat-icon-button>
              <mat-icon>rotate_right</mat-icon>
            </button>
          </div>
          <span class="space"></span>
          <div
            *ngIf="selfieBox.cropping"
            class="tool-button">
            <button
                (click)="cancelCrop()"
                mat-icon-button>
              <mat-icon>undo</mat-icon>
            </button>
          </div>
          <div
          *ngIf="selfieBox.cropping"
            class="tool-button">
            <button
                (click)="okCrop()"
                mat-icon-button>
                <mat-icon>done</mat-icon>
              </button>
            </div>
        </mat-toolbar-row>
      </mat-toolbar>

      <div
        class="crop-container">

      <angular-cropper
        class="img-origin box-crop"
        [cropperOptions]="selfieBox.cropConfig"
        [imageUrl]="selfieBox.base64"
        #selfieorg></angular-cropper>
       
        <div class="selfie-frame"></div>  
    </div>

    <div class="buttons">

      <button color="warn"
        (click)="cancelPreview()" mat-raised-button>
        {{ 'Dialog.Cancel' | translate }}</button>

      <button color="primary"
        (click)="saveImage()" mat-raised-button>
        {{ 'photo.save' | translate }}</button>
    </div>
</div>
</div>
</div>

<div class="modal-popup" *ngIf="waitsave">
  <div class="bg"></div>
  <div class="dlg-center">
    <ng-container [ngTemplateOutlet]="loading"></ng-container>
  </div>  
</div>

<div class="modal-popup" *ngIf="errsave || oksave">
  <div class="bg"></div>
  <div class="dlg-center">
    <p *ngIf="errsave">{{ errsave }}</p>
    <p *ngIf="oksave">{{ 'Saved' }}</p>
  </div>  
</div>

<ng-template #loading>
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>