import { Component, OnInit } from '@angular/core';
import { Product } from '../../../libs/proto/shop_pb';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLibService } from '../../../service/user/user-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { Color, PoloSize } from '../../../libs/proto/commUnity_pb';
import { GrpcRestaurantLibService } from '../../../service/grpc/restaurant/grpc-resto-lib.service';
import { RestaurantProduct, RestaurantSettings } from '../../../libs/proto/restaurant_pb';
import { StorageLibService } from '../../../service/storage/storage-lib.service';
import { LocationClass } from 'src/app/class/location/location';
import { desktopMode } from '../../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass']
})
export class MenuComponent implements OnInit {

  product: RestaurantProduct;
  colors: {k?: string, v?: string} = {};
  T = {};
  imageObject: Array<object> = [];
  imageSize: object = {};
  inLocation = false;
  forceLocation = false;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;

  constructor(
    private route: Router,
    private actRoute: ActivatedRoute,
    private userLib: UserLibService,
    private grpcRestoLib: GrpcRestaurantLibService,
    private storeLib: StorageLibService,
    private translate: TranslateService,
    private detector: DeviceLibService,
  ) { }
  get currencysymbol() {
    return this.userLib.Data.token?.getCustomer().getCurrencysymbol();
  }
  ngOnInit(): void {
    const thise = this;
    this.actRoute.paramMap.subscribe( p => {
      thise.grpcRestoLib.getRestaurantProducts({
        Offline: true,
        call: {
          req: p.get('id'),
        }
      }).then( ns => {
        if (ns.length === 0) {
          this.route.navigateByUrl('/resto');
          return;
        }
        thise.product = ns[0];
        if (this.userLib.Data.restaurantPage === undefined) {
          this.userLib.Data.restaurantPage = {};
        }
        if (thise.product) { this.userLib.Data.restaurantPage.id = thise.product.getId(); }
        thise.prepareImage();
      });
    });

    this.grpcRestoLib.getRestaurantSettings({
      Offline: this.storeLib.cache.restaurantSettings || false,
    }).then( s => {
      this.forceLocation = s.getForcegeolocation();
      this.check_location(s);
    }).catch( (e: Error) => {
    });

    // keep key
    const tLists: string[] = [];
    Object.keys(Color).map( c => {
      this.colors[Color[c]] = c;
      tLists.push('colors.' + c);
    });

    this.imageSize = {
      width: '100%',
    };

    // translate
    this.translate.get(tLists).toPromise().then( t => {
      this.T = t;
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }
  private prepareImage() {
    this.product.getImagesList().forEach( p => {
      this.imageObject.push(
        {
          image: p,
          thumbImage: p
        }
      );
    });
  }

  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }

  colorList(p?: Product): string {
    return p?.getColorsList().filter( v => v !== Color.COLOR_NO).map( v => (
      this.T['colors.' + this.colors[v]]
    )).join(',');
  }

  clickButton() {
    this.route.navigate(['/menu/confirm', this.product?.getId()]);
  }

  get hasColorSize() {
    return 0;
  }

  get product_qty() {
    if (this.product?.getQtyn()) {
       return this.product.getQt();
    }

    return '';
  }
  get has_table() {
    return (this.storeLib.get('table-id') || '') !== '';
  }

  private check_location(p: RestaurantSettings) {
    const loc = new LocationClass();
    loc.checkLocation({
      forceLocation: this.forceLocation,
      positions: [p.getLongitude(), p.getLatitude()],
      radius: p.getRadius()
    }).then( r => {
      if (r.ok) { this.inLocation = true; }
    }).catch( () => {});
  }

  get allow_add() {
    // if in location, allow to add (can pay with cash / lydia)
    if (this.inLocation) { return true; }

    // if not in location, allow to add only with lydia
    if ((this.userLib.Data.token?.getCustomer().getLydiaapi() || '') === '') {
      // no lidia
      return false;
    }
    return true;
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }
}
