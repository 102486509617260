import { Injectable } from '@angular/core';
import { MobileClient } from '../../libs/proto/MobileServiceClientPb';
import { ConfigLibService } from '../config/config-lib.service';
import * as grpcWeb from 'grpc-web';
import { MemberToken, SignInMobileRequest, MinVersion } from '../../libs/proto/mobile_pb';
import { UserLibService } from '../user/user-lib.service';
import { Subscriber } from 'rxjs';
import { News, Empty, OS } from '../../libs/proto/commUnity_pb';
import { communityAppType } from 'src/app/config/type';
import { LogLibService } from '../log/log-lib.service';

export interface GrpcLibServiceOption {
  /**
   * no retry if there is error (when token expire), when offline or resignin
   */
  noRetry?: boolean;
  /**
   * use data from offline
   */
  Offline?: boolean;
  /**
   * keep success data in cache
   */
  KeepInCache?: boolean;
  callback?: ((rets: any) => void);

  call?: {
    req?: any;
    data?: Array<any>;
    subscribe?: Subscriber<any>;
    treat?: boolean;
  };
}

interface GrpcLibServiceData {
  online?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class GrpcLibService {

  static ERR_UNEXPECTED = 0;
  static ERR_INVALID_TOKEN = 1;
  static ERR_INVALID_PASSWORD = 2;
  static ERR_PG = 3;

  static ERR_SIGIN = 'err-sigin';

  private mobileClient: MobileClient;

  /**
   * current data
   */
  private data: GrpcLibServiceData;

  constructor(
    private configLib: ConfigLibService,
    private userLib: UserLibService,
    private logLib: LogLibService,
  ) {
    this.data = {
      online: navigator.onLine,
    };
    this.mobileClient = new MobileClient(
      this.configLib.envoyURL,
      null,
      null
    );
  }
  /**
   * get mobile client
   */
  get MobileClient(): MobileClient {
    return this.mobileClient;
  }

  /**
   * export data
   */
  get Data(): GrpcLibServiceData {
    return this.data;
  }

  private validateError(err: grpcWeb.RpcError) {
    if (err.code === 2) {
      if (err.message.toLowerCase().indexOf('invalid token') >= 0) { return GrpcLibService.ERR_INVALID_TOKEN; }
      if (err.message.toLowerCase().substr(0, 3) === 'pg:') { return GrpcLibService.ERR_PG; }
      if (err.message.toLowerCase().indexOf('hashedPassword is not the hash of the given password') >= 0) {
        return GrpcLibService.ERR_INVALID_PASSWORD;
      }
      if (err.message.toLowerCase().indexOf('hashedPassword') >= 0) { return GrpcLibService.ERR_INVALID_PASSWORD; }
    }
    return GrpcLibService.ERR_UNEXPECTED;
  }

  /**
   * signin
   * @param user username/email
   * @param password user password
   */
  signIn(user: string, password: string): Promise<MemberToken>{
    const req =  new SignInMobileRequest();
    req.setUsername(user);
    req.setPassword(password);
    req.setMobileapp( communityAppType );
    req.setGroupid( this.userLib.Data.selectedGroup?.getId() );
    req.setCustomerid ( this.userLib.Data.selectedCustomer?.getId() );
    req.setMobileos( OS.OS_PWA );

    return new Promise<MemberToken>( (ok, ko) => {
      this.MobileClient.signIn(req, {}, (err: any, resp: MemberToken) => {

        if (err != null) {
          this.logLib.error('signIn', err);
          ko(err);
        } else {
          ok(resp);
        }
      });
    });
  }
  /**
   * handle error, resignin if needed. resolve (true if can resignin)
   *
   * @param err error
   */
  handleError(err: grpcWeb.RpcError, callback: (() => void), option: GrpcLibServiceOption) {
      // no retry
      if (option?.noRetry || false) {
        option.call.subscribe.error(err);
        return;
      }

      option.call.treat = true;
      const r = this.validateError(err) === GrpcLibService.ERR_INVALID_TOKEN;
      // invalid token
      if (r) {
        // if guest user, clear storage
        if (this.userLib.Data.token?.getProfile().getIsguest() || false) {
            this.userLib.clear();
            // redirect to signin
            location.reload();
            return;
        }

        if ((this.userLib.Data.login?.getUsername() || '') === '' || (this.userLib.Data.login?.getPassword() || '') === ''){
          // redirect to signin
          option.call.subscribe.error( Error(GrpcLibService.ERR_SIGIN) );
          return;
        }
        // resignin
        this.signIn(this.userLib.Data.login?.getUsername(), this.userLib.Data.login?.getPassword()).then( v => {

          // goto disclaimer?
          if (this.userLib.checkDisclaimer(v, callback)) { return; }

          this.userLib.updateUser(v);
          callback();
        })
        .catch( e => {
          // redirect to signin
          option.call.subscribe.error( Error(GrpcLibService.ERR_SIGIN) );
        });
        return;
      }
      // error, stay
      option.call.subscribe.error(err);
  }

  getOption(option?: GrpcLibServiceOption): GrpcLibServiceOption{
    let opt = option;
    if (opt === undefined) { opt = {KeepInCache: true}; }
    if (opt === null) { opt = {KeepInCache: true}; }
    if (opt.KeepInCache === undefined) { opt.KeepInCache = true; }
    if ((opt.call || null) === null) { opt.call = {}; }
    if ((opt.call?.data || null) == null) { opt.call.data = []; }

    return opt;
  }

  getMinimalVersion(): Promise<MinVersion>{
    return new Promise<MinVersion>( (ok, ko) => {
      this.MobileClient.getMinimalVersion(new Empty(), {}, (err: any, resp: MinVersion) => {

        if (err != null) {
          ko(err);
        } else {
          ok(resp);
        }
      });
    });
  }
  treatStatus(s: grpcWeb.Status, callback: (() => void), option: GrpcLibServiceOption) {
    const e = new grpcWeb.RpcError(s.code, s.details, {});

    this.handleError(e, callback, option);
  }
}
