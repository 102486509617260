import { Component, OnInit } from '@angular/core';
import { Product } from '../../libs/proto/shop_pb';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { GrpcProductLibService } from '../../service/grpc/product/grpc-product-lib.service';
import { UserLibService } from '../../service/user/user-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { Color, PoloSize } from '../../libs/proto/commUnity_pb';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.sass']
})
export class ProductComponent implements OnInit {

  product: Product;
  colors: {k?: string, v?: string} = {};
  T = {};
  imageObject: Array<object> = [];
  imageSize: object = {};

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;

  constructor(
    private route: Router,
    private actRoute: ActivatedRoute,
    private userLib: UserLibService,
    private grpcProductLib: GrpcProductLibService,
    private translate: TranslateService,
    private detector: DeviceLibService,
  ) { }
  get currencysymbol() {
    return this.userLib.Data.token?.getCustomer().getCurrencysymbol();
  }
  ngOnInit(): void {
    const thise = this;
    this.actRoute.paramMap.subscribe( p => {
      thise.grpcProductLib.getProducts({
        Offline: true,
        call: {
          req: p.get('id'),
        }
      }).then( ns => {
        if (ns.length === 0) {
          this.route.navigateByUrl('/shop');
          return;
        }
        thise.product = ns[0];
        if (this.userLib.Data.producstPage === undefined) {
          this.userLib.Data.producstPage = {};
        }
        if (thise.product) { this.userLib.Data.producstPage.id = thise.product.getId(); }
        thise.prepareImage();
      });
    });

    // keep key
    const tLists: string[] = [];
    Object.keys(Color).map( c => {
      this.colors[Color[c]] = c;
      tLists.push('colors.' + c);
    });

    this.imageSize = {
      width: '100%',
    };

    // translate
    this.translate.get(tLists).toPromise().then( t => {
      this.T = t;
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }
  private prepareImage() {
    this.product.getImagesList().forEach( p => {
      this.imageObject.push(
        {
          image: p,
          thumbImage: p
        }
      );
    });
  }

  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }

  colorList(p?: Product): string {
    return p?.getColorsList().filter( v => v !== Color.COLOR_NO).map( v => (
      this.T['colors.' + this.colors[v]]
    )).join(',');
  }

  clickButton() {
    if (this.userLib.Data.token?.getProfile().getIsguest()) {
      this.userLib.clear();
      this.userLib.Data.signOut = true;
      this.route.navigateByUrl('/login');
      return;
    }
    this.route.navigate(['/product/confirm', this.product?.getId()]);
  }

  get hasColorSize() {
    let c = 0;
    if ((this.colorList(this.product)?.length || 0) > 0) { c++; }
    if ((this.product?.getSizesList().join(',') || '') !== '') { c++; }
    if ((this.product?.getUrl() || '') !== '') { c++; }

    return c;
  }

  get product_qty() {
    if (this.product.getQtyn()) {
       return this.product.getQt();
    }

    return '';
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }
}
