<app-toolbar page="guest-reservations" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''" (eventsType)="viewChange($event)"></app-toolbar>

<div class="form-list" [ngClass]="{tablet: menuType==2}" *ngIf="reserves$ | async as reserves ">
  <mat-action-list *ngIf="isTypeList" class="pt-0">

    <mat-card class="p-0"
      (click)="open(reserve)"
      *ngFor="let reserve of reserves; let i = index;">
      <div *ngIf="reserve?.getHotixreservation().getArrivaldate()" class="table" [class]="i % 2 ? ' dark' : ''" (click)="open(reserve)"  >
        <div class="row" *ngIf="!isPast( reserve?.getHotixreservation().getDeparturedate())">
          <div class="col col1">
            <div class="table">
              <div class="row inner">
                <div class="col date month">{{ reservesK[reserve?.getId()]?.getHotixreservationdetail().getArrivaldate() | pipeDatefmt2 | amLocale: userLocale |amDateFormat: 'MMM'}}</div>
              </div>
              <div class="row inner">
                  <div class="col date day">
                    <span>
                      {{ reservesK[reserve?.getId()]?.getHotixreservationdetail().getArrivaldate() | pipeDatefmt2 | amLocale: userLocale |amDateFormat: 'dd DD'}}
                    </span>
                  </div>
              </div>
              <mat-icon *ngIf="!isPast( reserve?.getHotixreservation().getArrivaldate())"
              [class]="statusEventClass( reserve?.getHotixreservation().getStatus())"
              class="icon">{{ statusEventIcon( reserve?.getHotixreservation().getStatus()) }}</mat-icon>
        </div>
          </div>
          <div class="col border">
            <div>
              <mat-card-title class="title"> {{ ('guest.reservation-list-title' | translate).replace('%s', reserve?.getHotixreservation().getResaid() )  }}</mat-card-title>
              <mat-card-subtitle>{{ ('guest.reservation-list-subtitle' | translate).replace('%s', reserve?.getHotixreservation().getDeparturedate()) | amParse: 'YYYYMMDD' |amDateFormat: 'DD/MM/YYYY' }}{{ getGuestCount(reserve) }}</mat-card-subtitle>
            </div>
          </div>
        </div>
      </div>
    </mat-card>

  </mat-action-list>

  <div *ngIf="!isTypeList">
    <div class="table">
      <div class="row">
        <button class="col" (click)="prevMonth()" mat-button>
          <mat-icon>chevron_left</mat-icon>
        </button>
        <h2 class="top col">
          {{ calendarDate | calendarDate:(calendarView + 'ViewTitle'): userLocale }}
        </h2>
        <button class="col" (click)="nextMonth()" mat-button>
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
    <div [ngSwitch]="calendarView">
      <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [cellTemplate]="cellTemplate"
      [headerTemplate]="headTemplate"
      [locale]="userLocale"
      [viewDate]="calendarDate"
      [events]="calendarEvents"
      [refresh]="calendarRefresh"
      [activeDayIsOpen]="calendarActiveDayIsOpen"
      weekStartsOn="1"
      (dayClicked)="calendarDayClicked($event.day)">
    </mwl-calendar-month-view>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #cellTemplate let-day="day" let-locale="locale" >
  <div class="col-day" [ngClass]="{'cal-selected': selectedEvent(day.date |amDateFormat: 'YYYYMMDD')}">
      <span>
        {{ day.date | calendarDate:'monthViewDayNumber':locale }}
      </span>
  </div>
  <span class="evt-block" *ngIf="hasReservation(day.date)">
    <span class="evt evt1"></span>
  </span>
</ng-template>

<ng-template #headTemplate let-days="days" let-locale="locale">
  <span class="col-weekday" *ngFor="let d of days">
    {{ (d.date | amLocale: locale | amDateFormat: 'dd') | uppercase }}
  </span>

</ng-template>
