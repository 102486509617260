<app-toolbar page="meal-payment" (closePay)="closeme()"></app-toolbar>

<mat-card class="h-box">
    <mat-card-content>
      <p>
        {{ (messageTag | translate).replace('%s', message) }}
      </p>
    </mat-card-content>
</mat-card>

<mat-card class="img-box" *ngIf="pdfthumb">
  <img [src]="pdfthumb" />
</mat-card>
<div class="bottom" *ngIf="pdfthumb">
  <button (click)="loadpdf()" mat-fab class="but-download" color="primary"><mat-icon>download</mat-icon></button>
</div>
