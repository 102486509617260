import { Component, OnInit } from '@angular/core';
import { GrpcCompetitionLibService } from 'src/app/service/grpc/competition/grpc-competition-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Event} from 'src/app/libs/proto/commUnity_pb';
import { desktopMode } from '../../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';

@Component({
  selector: 'app-competition',
  templateUrl: './competition.component.html',
  styleUrls: ['./competition.component.sass']
})
export class CompetitionComponent implements OnInit {
  competition: Event;
  private actRouter$: Subscription;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;

  constructor(
  private grpcCompetitionLib: GrpcCompetitionLibService,
    private translate: TranslateService,
    private userLib: UserLibService,
    private actRoute: ActivatedRoute,
    private detector: DeviceLibService,
    ){
      userLib.setPageLanguage( this.translate );
    }

  ngOnInit(): void {
    const thise = this;
    this.actRouter$ = this.actRoute.paramMap.subscribe( p => {
      thise.grpcCompetitionLib.getCompetition({
        Offline: true,
        call: {
          req: p.get('id'),
        }
      }).then( ns => {
        thise.competition = ns[0];
      });
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }

  ngOnDestroy(): void {
    this.actRouter$.unsubscribe();
  }

  get dateFormat(): string {
    return this.userLib.dateFormat;
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }

}
