import { Component, OnInit } from '@angular/core';
import { UserLibService } from '../../service/user/user-lib.service';
import { Router } from '@angular/router';
import { GrpcDisclaimerLibService } from '../../service/grpc/disclaimer/grpc-disclaimer-lib.service';
import { Disclaimer } from '../../libs/proto/commUnity_pb';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.sass']
})
export class DisclaimerComponent implements OnInit {

  disclaimLoading = true;
  disclaim?: Disclaimer;
  disclaimAccept = false;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;

  constructor(
    private route: Router,
    private userLib: UserLibService,
    private grpcLib: GrpcDisclaimerLibService,
    private toast: MatSnackBar,
    private translate: TranslateService,
    private detector: DeviceLibService,
  ) {
    if ((this.userLib.disclaimerToken || null) == null) {
      this.route.navigateByUrl('/home');
    }
  }

  ngOnInit(): void {
    // reset token
    this.userLib.clear();
    this.grpcLib.getDisclaimer( this.userLib.disclaimerToken?.getToken() )
      .then(d => {
        this.disclaimLoading = false;
        this.disclaim = d;
    }).finally( () => {
      this.disclaimLoading = false;
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
       this.isLandscape = this.landscapeEvt.matches;
       this.menuType = this.getmenuType();
    });
  }

  accept() {
    const thise = this;
    this.grpcLib.approveDisclaimer( this.userLib.disclaimerToken?.getToken())
    .catch( e => {
      thise.translate.get([
        'disclaimer.title',
        'disclaimer.error'
      ]).toPromise().then( t => {

        thise.toast.open(
          t['disclaimer.error'], 'x', {
            duration: 500
          }
        );

      });
    })
    .finally( () => {
      if (this.userLib.disclaimerCallbackURL) {
        this.userLib.updateUser(this.userLib.disclaimerToken);
        this.userLib.disclaimerToken = null;
        this.route.navigateByUrl('/home');

        return;
      }
      this.userLib.afterLogin(this.userLib.disclaimerToken);
    });

  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }
}
