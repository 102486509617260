<app-toolbar page="payment"></app-toolbar>

<div class="bg"></div>
  <mat-card class="box-message">
    <h2 class="title">
      {{ 'payment.title' | translate }}
    </h2>
    <mat-card-content>
      <p>
        {{ (messageTag | translate).replace('%s', message) }}
      </p>
    </mat-card-content>
    <mat-card-footer>
      <button mat-flat-button [routerLink]="['/shop']">
        {{ 'Dialog.OK' | translate }}
      </button>
    </mat-card-footer>
  </mat-card>
