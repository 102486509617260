import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatStepper } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { TranslateService } from '@ngx-translate/core';

import { Table, Zone } from 'src/app/libs/proto/restaurant_pb';
import { Event, Member } from 'src/app/libs/proto/commUnity_pb';
import { UserLibService } from '../../service/user/user-lib.service';
import { GrpcReservationLibService } from '../../service/grpc/reservation/grpc-reservation-lib.service';
import { GrpcEventLibService } from 'src/app/service/grpc/event/grpc-event-lib.service';
import { DialogServiceService } from '../../service/dialog/dialog-service.service';
import { AddressConversionService } from '../../service/conversion/address/address-conversion.service';
import * as grpcWeb from 'grpc-web';

import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// Data shared between event component and reservation dialog
export interface DialogData {
  event: Event
}

// Object to be sent
interface Summary {
  zone?: Zone
  table?: Table
  attendees?: Member[]
}

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.sass'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    }
  ]
})
export class ReservationComponent implements OnInit {

  // Set number maximum attendees
  maxAttendees: number = 15;

  // User input fields
  numAttendees: UntypedFormControl;
  zone: UntypedFormControl;
  table: UntypedFormControl;
  attendees: UntypedFormControl;
  member: UntypedFormControl;

  // Form input group
  numAttendeesFormGroup: UntypedFormGroup;
  zoneFormGroup: UntypedFormGroup;
  attendeesFormGroup: UntypedFormGroup;

  // List for user input
  // List of available tables with at least n empty places
  tables: Table[]; // Not for user
  // List of available tables in the given zone
  tableSet: Table[];
  // List of zones
  zones: Zone[];
  // List of members
  members: Member[];

  // Final data
  summary: Summary

  // Autocomplete result
  filteredMembers: Observable<Member[]>;

  // Timeout callback object
  wizardTimeout: any;
  // Timeout before the wizard closes
  timeout: number = 15 * 60 * 1000;

  /* Used to detect device mode */
  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;
  menuType = 0;

  // Autocomplete input
  @ViewChild('memberAutocompleteInput') memberAutocompleteInput: ElementRef<HTMLInputElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<ReservationComponent>,
    private _formbuilder: UntypedFormBuilder,
    private route: Router,
    private grpcLib: GrpcReservationLibService,
    private grpcEventLib: GrpcEventLibService,
    private userLib: UserLibService,
    private dlgLib: DialogServiceService,
    private addConvLib: AddressConversionService,
    private detector: DeviceLibService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    // Fields
    this.numAttendees = new UntypedFormControl(1, Validators.required);
    this.zone = new UntypedFormControl(null, Validators.required);
    this.table = new UntypedFormControl(null, Validators.required);
    this.attendees = new UntypedFormControl([], [Validators.required, this.validateAttendees()]);
    this.member = new UntypedFormControl(''); // For autocomplete

    // Form group
    this.numAttendeesFormGroup = this._formbuilder.group({
      numAttendeesCtrl: this.numAttendees,
    });
    this.zoneFormGroup = this._formbuilder.group({
      zoneCtrl: this.zone,
      tableCtrl: this.table,
    });
    this.attendeesFormGroup = this._formbuilder.group({
      attendeesCtrl: this.attendees,
      memberCtrl: this.member,
    });

    // User is a member
    this.members = [this.userLib.Data.token?.getProfile()] || [];

    // Initialize data to send.
    this.summary = {
      zone: null,
      table: null,
      attendees: [],
    };

    // Observers
    this.numAttendees.valueChanges.subscribe(() => {
      this.zones = [];
      this.zoneFormGroup.reset();

      if (this.members?.length > 0) {
        this.attendees.setValue([this.members[0]]);
      } else {
        this.attendees.setValue([]);
      }

      // Reset member autocomplete
      this.member.setValue('');
    });

    this.zone.valueChanges.subscribe(change => {
      // Select zone.
      this.summary.zone = this.zones?.find(val => change == val.getId());

      // Select the table from the list of tables that is in the zone.
      const tables = this.tables?.filter(val => change == val.getZoneid());
      this.tableSet = tables;

      if (this.numAttendees.value > 1) {
        if (tables?.length > 0) {
          // Select the table from the list of tables that is in the zone and has the least amount of seats left.
          const selectedTable = tables?.reduce((a, b) => a.getSeats() < b.getSeats() ? a : b);
          this.table.setValue(selectedTable);
        } else {
          this.table.setValue(null);
        }
      } else {
        if (tables?.length == 1) {
          this.table.setValue(tables[0]);
        } else {
          this.table.reset();
        }
      }
    });

    this.table.valueChanges.subscribe(change => {
      this.summary.table = change;
    });

    this.attendees.valueChanges.subscribe(change => {
      // Select the attendees from list of attendees.
      this.summary.attendees = this.members?.filter(val => change.includes(val));
    });

    // Fetch list of available members after dialog finished opening.
    this.dialogRef.afterOpened().subscribe(() => {
      this.grpcLib.availableMembers(
        this.data.event?.getId()
      ).then(m => {
        // Remove this user from the list available members.
        const filtered = m.filter(val => val.getId() != this.userLib.Data.token?.getProfile().getId());
        this.members = this.members.concat(filtered);
      }).catch((err: grpcWeb.RpcError) => {
        console.error(err);
        // Show dialog
        this.translate.get([
          'reservation.error'
        ]).toPromise().then(t => {
          this.dlgLib.show(
            err.message,
            t['reservation.error'],
            () => {
              // Close wizard
              this.close();
            });
        });
      }).finally(() => {
        // Select this user as default attendee.
        this.attendees.setValue([this.userLib.Data.token?.getProfile()]);

        // Filter member autocomplete.
        this.filteredMembers = this.member.valueChanges.pipe(
          startWith(''),
          map(value => {
            const name = typeof value === 'string' ? value : `${value?.getFirstname()} ${value?.getLastname()}`;
            if (this.attendees?.value?.length >= this.numAttendees?.value) {
              return null;
            }
            return name ? this._filter(name as string) : this.members.slice().filter(member => !this.attendees?.value?.includes(member));
          }),
        );

        // Reservation timeout
        this.wizardTimeout = setTimeout(() => {
          this.dialogRef.close();
          this.translate.get(['reservation.error', 'reservation.timeout'])
            .toPromise().then(t => {
              this.dlgLib.show(t['reservation.timeout'], t['reservation.error']);
            });
        }, this.timeout);
      });
    });

    // Compute device mode
    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.wizardTimeout);
  }

  /**
   * Format address
   * 
   * @returns formatted address string
   */
  get addressText(): string {
    return this.addConvLib.toAddressText(this.data.event?.getAddress());
  }

  /**
   * Get list of tables from server.
   * Move forward if list of tables > 0
   * else show error message.
   * @param stepper reference to mat-stepper
   */
  goForwardAndGetTables(stepper: MatStepper): void {
    // Get list of tables
    this.grpcLib.availableTables(
      this.data.event?.getId(),
      this.numAttendees.value
    ).then(t => {
      // Set list of tables
      this.tables = t;

      // No table, show error
      if (t.length < 1) {
        this.translate.get(['reservation.error', 'reservation.number_places_err'])
          .toPromise().then(t => {
            this.dlgLib.show(t['reservation.number_places_err'].replace('%d', this.numAttendees?.value), t['reservation.error']);
          });

        return;
      }

      // Extract list of zones from list of tables.
      this.zones = [];
      t.forEach(val => {
        const zone = new Zone;
        zone.setId(val.getZoneid());
        zone.setName(val.getZonename());
        this.zones.push(zone);
      });

      // Remove duplicates
      this.zones = this.zones.filter((val, index, arr) => arr.findIndex(v => v.getId() == val.getId()) == index);

      // Set default zone if there is only 1 zone.
      if (this.zones.length == 1) {
        this.zone.setValue(this.zones[0].getId());
      }

      // Move forward
      stepper.next();
    }).catch((err: grpcWeb.RpcError) => {
      console.error(err);
      this.translate.get([
        'reservation.error'
      ]).toPromise().then(t => {
        this.dlgLib.show(err.message, t['reservation.error']);
      });
    });
  }

  /**
   * Event triggered by matChipRemove.
   * Remove attendee.
   * @param attendee member object
   */
  onAttendeeRemoved(attendee: Member) {
    const attendees = this.attendees.value as Member[];
    this.removeFirst(attendees, attendee);
    this.attendees.setValue(attendees);
    // Clear autocomplete
    this.member.setValue('');
  }

  /**
   * Remove object from list of objects.
   * @param array list of objects
   * @param toRemove object to be removed
   */
  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  /**
   * If you want the option's control value (what is saved in the form) to be different
   * than the option's display value (what is displayed in the text field),
   * you'll need to set the displayWith property on your autocomplete element.
   * A common use case for this might be if you want to save your data as an object,
   * but display just one of the option's string properties.
   * @param member 
   * @returns 
   */
  displayFn(member: Member): string {
    return member ? `${member.getFirstname()} ${member.getLastname()}` : '';
  }

  /**
   * Event triggered on selecting autocomplete option.
   * Set the input to empty
   * @param event 
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value) {
      const attendees = this.attendees.value as Member[];
      attendees.push(event.option.value);
      this.attendees.setValue(attendees);
    }
    this.member.setValue('');
    this.memberAutocompleteInput.nativeElement.value = '';
    this.memberAutocompleteInput.nativeElement.blur();
  }

  /**
   * Filter the list of autocomplete member list
   * @param value 
   * @returns list of filtered members
   */
  private _filter(value: string): Member[] {
    const filterValue = value.toLowerCase();

    return this.members.filter(member => !this.attendees?.value?.includes(member)
      && `${member.getFirstname()} ${member.getLastname()}`.toLowerCase().includes(filterValue));
  }

  /**
   * Disable option is select when the user itself is the attendee or when
   * the number of selected attendees has reached the maximum number `numAttendees`
   * @param opt any value that can be compared, be int or string
   * @returns boolean
   */
  isOptionDisabled(opt: any): boolean {
    return this.attendees?.value?.length >= this.numAttendees?.value && !this.attendees?.value?.find(el => el == opt)
      || this.userLib.Data.token?.getProfile() == opt;
  }

  /**
   * Create event table reservation
   */
  async confirm(): Promise<void> {
    if (this.data.event == null
      || this.summary.table == null
      || this.summary.attendees == null) {
      this.translate.get([
        'reservation.error',
        'reservation.confirm_err'
      ]).toPromise().then(t => {
        this.dlgLib.show(t['reservation.confirm_err'], t['reservation.error']);
      });

      return;
    }

    // Shallow copy of list of tables of the given zone
    let copy_tables: Table[];

    if (this.numAttendees.value == 1) {
      // Only 1 table
      copy_tables = [this.summary?.table];
    } else if (this.numAttendees.value > 1) {
      // Set of all tables in zone
      copy_tables = [...this.tableSet];
    }

    // GRPC Web error object
    let e: grpcWeb.RpcError;

    // Consume copy_tables until making table reservation successfully or no table left.
    while (copy_tables.length > 0) {
      const table = copy_tables?.reduce((a, b) => a.getSeats() < b.getSeats() ? a : b);
      this.removeFirst(copy_tables, table);
      this.summary.table = table;

      await this.grpcLib.addEventTableReservation(this.data.event?.getId(), this.summary?.table?.getId(), this.summary?.attendees).then(() => {
        clearTimeout(this.wizardTimeout);

        this.translate.get([
          'reservation.confirm_title',
          'reservation.confirm_msg_person',
          'reservation.confirm_msg_people'
        ]).toPromise().then(t => {
          let msg = t['reservation.confirm_msg_person']
            .replace('%s1', this.summary?.table?.getCode())
            .replace('%s2', this.summary?.table?.getZonename());
          if (this.summary?.attendees?.length > 1) {
            msg = t['reservation.confirm_msg_people']
              .replace('%d', this.summary?.attendees?.length)
              .replace('%s1', this.summary?.table?.getCode())
              .replace('%s2', this.summary?.table?.getZonename());
          }

          this.dlgLib.show(msg,
            t['reservation.confirm_title'],
            () => {
              this.grpcEventLib.getEvent({
                Offline: false
              }).finally(() => {
                this.route.navigateByUrl('/events');
                this.dialogRef.close();
              });
            });
        });

        // Set error to null
        e = null;
      }).catch((err: grpcWeb.RpcError) => {
        console.error(err);

        // Copy error object
        e = err;
      });

      // If no error break out of the loop
      if (e == null) {
        break;
      }
    }

    // Error is not null, display dialog.
    if (e != null) {
      this.translate.get([
        'reservation.error',
        'reservation.duplicate_err',
        'reservation.confirm_err'
      ]).toPromise().then(t => {
        if (e.message.includes('duplicate key error')) {
          // Duplicate key
          this.dlgLib.show(t['reservation.duplicate_err'], t['reservation.error']);
        } else {
          this.dlgLib.show(t['reservation.confirm_err'], t['reservation.error']);
        }
      });
    }
  }

  /**
   * Check whether the user has selected all
   * the attendees.
   * @returns ValidatorFn
   */
  validateAttendees(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value?.length < this.numAttendees?.value) {
        return { attendees_lt_num: true };
      }
      if (control.value?.length > this.numAttendees?.value) {
        return { attendees_gt_num: true };
      }
      return null;
    };
  }

  /**
   * Show information pop-up regarding to the reservation.
   */
  info(): void {
    this.translate.get(['reservation.information'])
      .toPromise().then(t => {
        this.dlgLib.show(this.data?.event.getInforeservation(), t['reservation.information']);
      });
  }

  /**
   * Closes wizard dialog.
   * 
   * @returns void
   */
  close(): void {
    this.dialogRef.close();
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
  getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4) {
        if (this.isLandscape) { return 2; }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }

}
