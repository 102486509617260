<app-toolbar page="place" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''" [source]="source" [data]="sourceId" [pageTitle]="place?.getName()"></app-toolbar>

<div class="form-list" *ngIf="menuType!=2">

<div class="top-img-container">
  <swiper [navigation]="true">
    <ng-template *ngFor="let p of place?.getImagesList()" swiperSlide>
      <div [ngStyle]="{'background-image': 'url(' + p + ')'}"
        class="top-img"></div>
    </ng-template>
  </swiper>
</div>

  <div
    [ngClass]="{'no-picture': place?.getImagesList().length == 0}"
    class="title">
    {{ place?.getName() }}
  </div>

  <div class="table-line">

  <ng-container
    [ngTemplateOutlet]="line"
    [ngTemplateOutletContext]="{type: 'description', p: place}">
  </ng-container>

  <ng-container
    [ngTemplateOutlet]="line"
    [ngTemplateOutletContext]="{type: 'address', p: place}">
  </ng-container>

  <ng-container
    [ngTemplateOutlet]="line"
    [ngTemplateOutletContext]="{type: 'phone', p: place}">
  </ng-container>

  <ng-container
  [ngTemplateOutlet]="line"
  [ngTemplateOutletContext]="{type: 'mobile', p: place}">
  </ng-container>

  <ng-container
  [ngTemplateOutlet]="line"
  [ngTemplateOutletContext]="{type: 'snapshat', p: place}">
  </ng-container>

  <ng-container
  [ngTemplateOutlet]="line"
  [ngTemplateOutletContext]="{type: 'skype', p: place}">
  </ng-container>

  <ng-container
  [ngTemplateOutlet]="line"
  [ngTemplateOutletContext]="{type: 'whatsapp', p: place}">
  </ng-container>

  <ng-container
  [ngTemplateOutlet]="line"
  [ngTemplateOutletContext]="{type: 'mail', p: place}">
  </ng-container>

  <ng-container
  [ngTemplateOutlet]="line"
  [ngTemplateOutletContext]="{type: 'director', p: place}">
  </ng-container>

  <ng-container
  [ngTemplateOutlet]="line"
  [ngTemplateOutletContext]="{type: 'restaurant', p: place}">
  </ng-container>

  <ng-container
  [ngTemplateOutlet]="line"
  [ngTemplateOutletContext]="{type: 'architect', p: place}">
  </ng-container>

  <ng-container
  [ngTemplateOutlet]="line"
  [ngTemplateOutletContext]="{type: 'year', p: place}">
  </ng-container>

  <ng-container
  [ngTemplateOutlet]="line"
  [ngTemplateOutletContext]="{type: 'area', p: place}">
  </ng-container>

  </div>

  <div class="social">
    <div class="social-line">
      <div class="social-col">

    <ng-container
    [ngTemplateOutlet]="social"
    [ngTemplateOutletContext]="{type: 'url', p: place}">
    </ng-container>

    <ng-container
    [ngTemplateOutlet]="social"
    [ngTemplateOutletContext]="{type: 'youtube', p: place}">
    </ng-container>

    <ng-container
    [ngTemplateOutlet]="social"
    [ngTemplateOutletContext]="{type: 'facebook', p: place}">
    </ng-container>

    <ng-container
    [ngTemplateOutlet]="social"
    [ngTemplateOutletContext]="{type: 'twitter', p: place}">
    </ng-container>

    <ng-container
    [ngTemplateOutlet]="social"
    [ngTemplateOutletContext]="{type: 'pinterest', p: place}">
    </ng-container>

    <ng-container
    [ngTemplateOutlet]="social"
    [ngTemplateOutletContext]="{type: 'instagram', p: place}">
    </ng-container>

  </div>
  </div>
  </div>
</div>

<ng-template #line let-type='type' let-p='p'>
  <div
    *ngIf="toPlaceData(type, p)"
    class="line">
    <div class="col col1">
      <img
        *ngIf="['area','year'].indexOf(type) === -1"
        src="{{ toIcon(type) }}" />
    </div>
    <div class="col col2">
      <p [innerHTML]="toPlaceData(type, p) | pipeDescription"
        [ngClass]="{'has-action': ['phone','mobile',
                   'url','youtube', 'address',
                   'facebook','twitter','pinterest',
                   'linkedin','instagram','mail'].indexOf(type) >= 0}"
        (click)="action(type, p)">
      </p>
    </div>
  </div>
</ng-template>

<ng-template #social let-type='type' let-p='p'>
    <img (click)="action(type, p)"
      class="social-item"
      *ngIf="toPlaceData(type, p)"
      src="{{ toIcon(type) }}" />

</ng-template>

<div class="mode-tablet" *ngIf="menuType==2">
  <div class="left">
    <div class="top-img-container">
      <swiper [navigation]="true">
        <ng-template *ngFor="let p of place?.getImagesList()" swiperSlide>
          <div [ngStyle]="{'background-image': 'url(' + p + ')'}" class="top-img"></div>
        </ng-template>
      </swiper>
    </div>
  </div>

  <div class="right">
    <div [ngClass]="{'no-picture': place?.getImagesList().length == 0}" class="title">
      {{ place?.getName() }}
    </div>
  
    <div class="table-line">
  
      <ng-container [ngTemplateOutlet]="line" [ngTemplateOutletContext]="{type: 'description', p: place}">
      </ng-container>
  
      <ng-container [ngTemplateOutlet]="line" [ngTemplateOutletContext]="{type: 'address', p: place}">
      </ng-container>
  
      <ng-container [ngTemplateOutlet]="line" [ngTemplateOutletContext]="{type: 'phone', p: place}">
      </ng-container>
  
      <ng-container [ngTemplateOutlet]="line" [ngTemplateOutletContext]="{type: 'mobile', p: place}">
      </ng-container>
  
      <ng-container [ngTemplateOutlet]="line" [ngTemplateOutletContext]="{type: 'snapshat', p: place}">
      </ng-container>
  
      <ng-container [ngTemplateOutlet]="line" [ngTemplateOutletContext]="{type: 'skype', p: place}">
      </ng-container>
  
      <ng-container [ngTemplateOutlet]="line" [ngTemplateOutletContext]="{type: 'whatsapp', p: place}">
      </ng-container>
  
      <ng-container [ngTemplateOutlet]="line" [ngTemplateOutletContext]="{type: 'mail', p: place}">
      </ng-container>
  
      <ng-container [ngTemplateOutlet]="line" [ngTemplateOutletContext]="{type: 'director', p: place}">
      </ng-container>
  
      <ng-container [ngTemplateOutlet]="line" [ngTemplateOutletContext]="{type: 'restaurant', p: place}">
      </ng-container>
  
      <ng-container [ngTemplateOutlet]="line" [ngTemplateOutletContext]="{type: 'architect', p: place}">
      </ng-container>
  
      <ng-container [ngTemplateOutlet]="line" [ngTemplateOutletContext]="{type: 'year', p: place}">
      </ng-container>
  
      <ng-container [ngTemplateOutlet]="line" [ngTemplateOutletContext]="{type: 'area', p: place}">
      </ng-container>
  
    </div>
  
    <div class="social">
      <div class="social-line">
        <div class="social-col">
  
          <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{type: 'url', p: place}">
          </ng-container>
  
          <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{type: 'youtube', p: place}">
          </ng-container>
  
          <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{type: 'facebook', p: place}">
          </ng-container>
  
          <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{type: 'twitter', p: place}">
          </ng-container>
  
          <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{type: 'pinterest', p: place}">
          </ng-container>
  
          <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{type: 'instagram', p: place}">
          </ng-container>
  
        </div>
      </div>
    </div>
  </div>
</div>
