import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject } from 'rxjs';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { CalendarView, CalendarEvent } from 'angular-calendar';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';

import { registerLocaleData, WeekDay } from '@angular/common';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { desktopMode } from 'src/app/config/type';
import { HotixReservation, HotixReservationDetail, HotixReservationDetailResp, HotixReservationResp, HotixReservationStatus, HotixRoomBillDetailResp, HotixStayReason } from 'src/app/libs/proto/hotix_pb';
import { GrpcGuestLibService } from 'src/app/service/grpc/guest/grpc-guest-lib.service';
import { GrpcLibService } from 'src/app/service/grpc/grpc-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { GuestConversionService } from 'src/app/service/conversion/guest/guest-conversion.service';
@Component({
  selector: 'app-guest-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.sass']
})
export class GuestReservationsComponent implements OnInit {

  calendarView: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  calendarDate: Date = new Date();
  calendarSelect: Date = new Date();
  calendarRefresh: Subject<any> = new Subject();
  calendarEvents: CalendarEvent[];
  calendarActiveDayIsOpen: boolean;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;
  menuType = 0;

  tkeys = {};

  isTypeList = true;
  reserves$: Promise<HotixReservation[]>;
  // id / detail
  reservesK: Map<number, HotixReservationDetail> = new Map<number, HotixReservationDetail>();
  // date / id
  reservesD: Map<string, number> = new Map<string, number>();

  constructor(
    private route: Router,
    private grpcLib: GrpcGuestLibService,
    private translate: TranslateService,
    private dateAdp: DateAdapter<Date>,
    private detector: DeviceLibService,
    private userLib: UserLibService,
    private storeLib: StorageLibService,
    private convLib: GuestConversionService,
  ) {
    userLib.setPageLanguage( this.translate );
  }

  prepareTranslate(callback: ()=>void){
    this.translate.get([
      'guest.reservation-adult',
      'guest.reservation-adults',
      'guest.reservation-child',
      'guest.reservation-childs',
      'guest.reservation-baby',
      'guest.reservation-babys',
    ]).toPromise().then( v => {
      this.tkeys = v;
    }).finally( () => callback() );
  }

  ngOnInit(): void {
    this.dateAdp.setLocale(this.userLocale);
    if (this.userLocale == 'fr') registerLocaleData(localeFr);
    if (this.userLocale == 'nl') registerLocaleData(localeNl);
    if (this.userLocale == 'es') registerLocaleData(localeEs);
    if (this.userLocale == 'pt') registerLocaleData(localePt);

    const thise = this;
    const prevcache = this.storeLib.cache.guestReservation;
    this.prepareTranslate( () => {
    this.reserves$ = this.grpcLib.getHotixReservation({
      Offline: prevcache || false,
    }).then( es => {
        // keep detail in reservesK
        thise.grpcLib.getHotixReservationDetail({
          Offline: prevcache,
        }).then( dt => {
          for(var i=0;i<(dt || []).length;i++){
            thise.reservesK[dt[i].getReservationid()]=dt[i];
          }

          for(var i=0;i<(es || []).length;i++) {
            // key
            thise.reservesD[es[i].getHotixreservation().getArrivaldate().toString()] = es[i].getHotixreservation().getResaid();
          }
  
        });

      return of(es).toPromise();
    })
    .catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }

  get userLocale(): string {
    return this.userLib.Data.lg || 'en';
  }

  open(e: HotixReservation){
    e.getHotixreservation().getRoomtype()
    this.route.navigate(['/guest/reservations/', e.getHotixreservation().getResaid()]);
  }

  getGuestCount(e: HotixReservation): string {
    let a:HotixReservationDetail = this.reservesK[ e.getHotixreservation().getResaid() ];
    if (!a) return '';

    var s:string[]=[];

    if (a.getHotixreservationdetail().getNumadults() > 0){
      let lb = a.getHotixreservationdetail().getNumadults() > 1 ? 's' : '';
      s.push(this.tkeys['guest.reservation-adult'+lb].replace('%s', a.getHotixreservationdetail().getNumadults()));
    }
    if (a.getHotixreservationdetail().getNumkids() > 0){
      let lb = a.getHotixreservationdetail().getNumkids() > 1 ? 's' : '';
      s.push(this.tkeys['guest.reservation-child'+lb].replace('%s', a.getHotixreservationdetail().getNumkids()));
    }
    if (a.getHotixreservationdetail().getNumbabies() > 0){
      let lb = a.getHotixreservationdetail().getNumbabies() > 1 ? 's' : '';
      s.push(this.tkeys['guest.reservation-baby'+lb].replace('%s', a.getHotixreservationdetail().getNumbabies()));
    }

    return (s.length > 0 ? ' - ' : '') +  s.join(', ');
  }

  hasReservation(d: Date): boolean {
    return this.reservesD.get( moment(d).format('YYYYMMDD') ) > 0;
  }

  viewChange(type: string) {
    switch (type) {
      case 'event.list':
        this.isTypeList = true;
        break;
      case 'event.calendar':
        this.isTypeList = false;
        break;
      case 'event.today':
        this.calendarDate = new Date();
        break;        
      default:
        break;
    }
  }
  get dateFormat(): string {
    return this.userLib.dateFormat;
  }

  isPast(d: number) {
    return false;
    return +moment().format('YYYYMMDD') > d;
  }

  calendarDayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.calendarDate)) {
      if (
        (isSameDay(this.calendarDate, date) && this.calendarActiveDayIsOpen === true) ||
        events.length === 0
      ) {
        this.calendarActiveDayIsOpen = false;
      } else {
        this.calendarActiveDayIsOpen = true;
      }
      this.calendarSelect = date;
    }
  }

  selectedEvent(d: string) {
    return  moment(this.calendarSelect).format('YYYYMMDD') === d;
  }
  getMonth(d: number) {
    return moment(d + '', 'YYYYMMDD').locale(this.userLocale).format('MMM');
  }
  prevMonth() {
    this.calendarDate = moment(this.calendarDate).subtract(1, 'month').toDate();
  }
  nextMonth() {
    this.calendarDate = moment(this.calendarDate).add(1, 'month').toDate();
  }
  statusEventIcon(att: HotixReservationStatus) {    
    switch (att){
    case HotixReservationStatus.HOTIXRESERVATIONSTATUS_CONFIRMED:
      return 'check_circle_outline';
    case HotixReservationStatus.HOTIXRESERVATIONSTATUS_CANCELLED:
      return 'highlight_off';
    case HotixReservationStatus.HOTIXRESERVATIONSTATUS_PENDING_PRE_CHECKIN:
      return 'save';
    default:
      return 'help_outline';
    }
  }
  statusEventClass(att: HotixReservationStatus) {
    switch (att){
    case HotixReservationStatus.HOTIXRESERVATIONSTATUS_CONFIRMED:
      return 'attend';
    case HotixReservationStatus.HOTIXRESERVATIONSTATUS_CANCELLED:
      return 'not-attend';
    case HotixReservationStatus.HOTIXRESERVATIONSTATUS_PENDING_PRE_CHECKIN:
      return 'draft';
    default:
      return '';
    }
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }
}
