<app-toolbar page="benefits" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''"></app-toolbar>

<div class="form-list" [ngClass]="{tablet: menuType==2}" *ngIf="benefits$ | async as benefits">
  <mat-action-list *ngFor="let benefit of benefits">
    <mat-card>
      <div (click)="open(benefit.getId())">
        <mat-card-header>
          <div mat-card-avatar class="img"
          [ngStyle]="{'background-image': 'url(' + benefit.getImage() + ')'}">
        </div>
        <mat-card-title> {{ benefit.getTitle() }}</mat-card-title>
        <mat-card-subtitle>{{ benefit.getShortdescription()}}</mat-card-subtitle>
        <mat-card-subtitle> {{ benefit.getPublishdate() | amParse: 'YYYYMMDD' | amDateFormat: dateFormat}}</mat-card-subtitle>
        </mat-card-header>
      </div>
    </mat-card>
  </mat-action-list>
</div>
<ng-template #loading>
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
