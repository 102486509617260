<app-toolbar page="event" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''"></app-toolbar>

<div class="form-list" *ngIf="menuType!=2">

  <!-- `done` is true when all events have been loaded. -->
  <swiper #swiper *ngIf="done" [config]="configSwiper">

    <ng-template *ngFor="let event of events; index as i" swiperSlide>

      <div class="card-main">

        <mat-card class="event-card">
          <div class="top-image" [ngStyle]="{'background-image': 'url(' + event?.getEvent().getImage() +')'}">
            <div class="swiper-button-prev" (click)="slidePrev()"
              [ngClass]="{'swiper-button-disabled':swiper.swiperRef?.realIndex==0}"
              [ngStyle]="{'display':events?.length==1 ? 'none' : 'block'}"></div>
            <div class="swiper-button-next" (click)="slideNext()"
              [ngClass]="{'swiper-button-disabled':swiper.swiperRef?.realIndex==events?.length-1}"
              [ngStyle]="{'display':events?.length==1 ? 'none' : 'block'}"></div>
          </div>
          <!-- Reservation only -->
          <div *ngIf="event?.getEvent().getHastablereservation()" class="reservation-table">
            <h1 *ngIf="event?.getAttending() === 1 && eventTableReservations[i]">
              {{ ('reservation.table_msg' | translate).replace('%s', eventTableReservations[i].getTablecode()) }}
            </h1>
            <h1 *ngIf="!canReserve(event?.getEvent().getCancanceleventuntil()) && event?.getAttending() === 0">
              {{ 'reservation.closed' | translate }}
            </h1>
            <h1 *ngIf="event?.getAttending() === 1 && !eventTableReservations[i]">
              {{ 'event.ko' | translate }}
            </h1>
          </div>
          <div *ngIf="isFuture(event?.getEvent().getDate())" class="box-subscribe">
            <button mat-button *ngIf="event?.getEvent().getQt() != -2 && !event?.getEvent().getHastablereservation()"
              [disabled]="event?.getEvent().getQt() == 0" [color]=" event?.getAttending() === 1 ? '' : 'accent'"
              (click)="attend(event)">
              {{ 'event.accept' | translate }}
            </button>
            <button mat-button *ngIf="event?.getEvent().getQt() != -2 && !event?.getEvent().getHastablereservation()" class="btn-right"
              [color]=" event?.getAttending() === 2 ? '' : 'accent'" (click)="notAttend(event)">
              {{ 'event.not_accept' | translate }}
            </button>
            <!-- Reservation only -->
            <button mat-button
              *ngIf="event?.getEvent().getHastablereservation() && canReserve(event?.getEvent().getCancanceleventuntil()) && event?.getAttending() === 0"
              [color]="(event?.getAttending() === 1 || !canReserve(event?.getEvent().getCancanceleventuntil())) ? '' : 'accent'"
              (click)="reserve(event)">
              {{ 'event.reserve' | translate }}
            </button>
            <button mat-button
              *ngIf="event?.getEvent().getHastablereservation() && canCancel(event?.getEvent().getCancanceleventuntil()) && event?.getAttending() === 1"
              [color]="canCancel(event?.getEvent().getCancanceleventuntil()) ? 'accent' : ''" (click)="cancel(event)">
              {{ 'event.cancel' | translate }}
            </button>
          </div>
          <div class="table">
            <div class="row">
              <div class="col1">
                <div class="col1">
                  <div class="line">
                    {{ event?.getEvent().getDate() | amParse: 'YYYYMMDD' | amDateFormat: 'DD/MM' }}
                  </div>
                  <div class="line">
                    {{ event?.getEvent().getDate() | amParse: 'YYYYMMDD' | amDateFormat: 'YYYY' }}
                  </div>
                  <div class="line time" *ngIf="showTime(event)">
                    {{ event?.getEvent().getTimefrom() }}
                    -
                    {{ event?.getEvent().getTimeto() }}
                  </div>
                </div>
              </div>
              <div class="col">
                <mat-card-header>
                  <mat-card-title>{{ event?.getEvent().getTitle() }}</mat-card-title>
                  <mat-card-subtitle class="col-address">{{ addressText }}</mat-card-subtitle>
                  <mat-card-subtitle *ngIf="event?.getEvent().getQt() && event?.getEvent().getQt() !== -1 && event?.getEvent().getQt() !== -2"
                    class="col-ava">{{ ('event.available' | translate).replace('%s', event?.getEvent().getQt() ) }}
                  </mat-card-subtitle>
                  <mat-card-subtitle *ngIf="event?.getEvent().getUrl()" class="mb-0 info-link">
                    <a class="pl-0" mat-flat-button target="_blank" href="{{ event?.getEvent().getUrl() }}">
                      <mat-icon>language</mat-icon>
                      {{ 'new.more_info' | translate }}
                    </a>
                  </mat-card-subtitle>
                </mat-card-header>
              </div>
            </div>
          </div>
          <div class="table mt-0">
            <div class="row">
              <div class="col">
                <mat-card-subtitle class="mb-0 place-link" *ngIf="hasplace">
                  <a class="pl-0" mat-flat-button
                    [routerLink]="[ '/place/event/', event?.getEvent().getId() , event?.getEvent().getPlaceid() ]"
                    routerLinkActive="active">
                    <mat-icon>place</mat-icon>
                    {{ ('new.info_place' | translate).replace('%s', event?.getEvent().getPlacename()) }}
                  </a>
                </mat-card-subtitle>
              </div>
            </div>
          </div>
        </mat-card>

        <div class="body">
          <p [innerHTML]="event?.getEvent().getDescription() | pipeDescription"></p>
        </div>
      </div>

    </ng-template>
  </swiper>
</div>

<div class="mode-tablet" *ngIf="menuType==2">

  <!-- `done` is true when all events have been loaded. -->
  <swiper #swiper *ngIf="done" [config]="configSwiper">

    <ng-template *ngFor="let event of events; index as i" swiperSlide>

      <div class="swiper-button-prev" (click)="slidePrev()"
        [ngClass]="{'swiper-button-disabled':swiper.swiperRef?.realIndex==0}"
        [ngStyle]="{'display':events?.length==1 ? 'none' : 'block'}"></div>
      <div class="swiper-button-next" (click)="slideNext()"
        [ngClass]="{'swiper-button-disabled':swiper.swiperRef?.realIndex==events?.length-1}"
        [ngStyle]="{'display':events?.length==1 ? 'none' : 'block'}"></div>

      <div class="card-main">
        <div class="left">
          <mat-card class="event-card">
            <div class="top-image" [ngStyle]="{'background-image': 'url(' + event?.getEvent().getImage() +')'}">
            </div>
            <!-- Reservation only -->
            <div *ngIf="event?.getEvent().getHastablereservation()" class="reservation-table">
              <h1 *ngIf="event?.getAttending() === 1 && eventTableReservations[i]">
                {{ ('reservation.table_msg' | translate).replace('%s', eventTableReservations[i].getTablecode()) }}
              </h1>
              <h1 *ngIf="!canReserve(event?.getEvent().getCancanceleventuntil()) && event?.getAttending() === 0">
                {{ 'reservation.closed' | translate }}
              </h1>
              <h1 *ngIf="event?.getAttending() === 1 && !eventTableReservations[i]">
                {{ 'event.ko' | translate }}
              </h1>
            </div>
            <div *ngIf="isFuture(event?.getEvent().getDate())" class="box-subscribe">
              <button mat-button *ngIf="event?.getEvent().getQt() != -2 && !event?.getEvent().getHastablereservation()"
                [disabled]="event?.getEvent().getQt() == 0" [color]=" event?.getAttending() === 1 ? '' : 'accent'"
                (click)="attend(event)">
                {{ 'event.accept' | translate }}
              </button>
              <button mat-button *ngIf="event?.getEvent().getQt() != -2 && !event?.getEvent().getHastablereservation()" class="btn-right"
                [color]=" event?.getAttending() === 2 ? '' : 'accent'" (click)="notAttend(event)">
                {{ 'event.not_accept' | translate }}
              </button>
              <!-- Reservation only -->
              <button mat-button
                *ngIf="event?.getEvent().getHastablereservation() && canReserve(event?.getEvent().getCancanceleventuntil()) && event?.getAttending() === 0"
                [color]="(event?.getAttending() === 1 || !canReserve(event?.getEvent().getCancanceleventuntil())) ? '' : 'accent'"
                (click)="reserve(event)">
                {{ 'event.reserve' | translate }}
              </button>
              <button mat-button
                *ngIf="event?.getEvent().getHastablereservation() && canCancel(event?.getEvent().getCancanceleventuntil()) && event?.getAttending() === 1"
                [color]="canCancel(event?.getEvent().getCancanceleventuntil()) ? 'accent' : ''" (click)="cancel(event)">
                {{ 'event.cancel' | translate }}
              </button>
            </div>
            <div class="table">
              <div class="row">
                <div class="col1">
                  <div class="col1">
                    <div class="line">
                      {{ event?.getEvent().getDate() | amParse: 'YYYYMMDD' | amDateFormat: 'DD/MM' }}
                    </div>
                    <div class="line">
                      {{ event?.getEvent().getDate() | amParse: 'YYYYMMDD' | amDateFormat: 'YYYY' }}
                    </div>
                    <div class="line time" *ngIf="showTime(event)">
                      {{ event?.getEvent().getTimefrom() }}
                      -
                      {{ event?.getEvent().getTimeto() }}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <mat-card-header>
                    <mat-card-title>{{ event?.getEvent().getTitle() }}</mat-card-title>
                    <mat-card-subtitle class="col-address">{{ addressText }}</mat-card-subtitle>
                    <mat-card-subtitle *ngIf="event?.getEvent().getQt() && event?.getEvent().getQt() !== -1 && event?.getEvent().getQt() !== -2"
                      class="col-ava">{{ ('event.available' | translate).replace('%s', event?.getEvent().getQt() ) }}
                    </mat-card-subtitle>
                    <mat-card-subtitle *ngIf="event?.getEvent().getUrl()" class="mb-0 info-link">
                      <a class="pl-0" mat-flat-button target="_blank" href="{{ event?.getEvent().getUrl() }}">
                        <mat-icon>language</mat-icon>
                        {{ 'new.more_info' | translate }}
                      </a>
                    </mat-card-subtitle>
                  </mat-card-header>
                </div>
              </div>
            </div>
            <div class="table mt-0">
              <div class="row">
                <div class="col">
                  <mat-card-subtitle class="mb-0 place-link" *ngIf="hasplace">
                    <a class="pl-0" mat-flat-button
                      [routerLink]="[ '/place/event/', event?.getEvent().getId() , event?.getEvent().getPlaceid() ]"
                      routerLinkActive="active">
                      <mat-icon>place</mat-icon>
                      {{ ('new.info_place' | translate).replace('%s', event?.getEvent().getPlacename()) }}
                    </a>
                  </mat-card-subtitle>
                </div>
              </div>
            </div>
          </mat-card>
        </div>

        <div class="right">
          <div class="body">
            <p [innerHTML]="event?.getEvent().getDescription() | pipeDescription"></p>
          </div>
        </div>
      </div>

    </ng-template>
  </swiper>
</div>