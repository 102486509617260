import { Injectable } from '@angular/core';
import { LogLibService } from '../log/log-lib.service';

interface ServiceReloadFlag {
  member?: boolean;
  album?: boolean;
  event?: boolean;
  news?: boolean;
  news2?: boolean;
  news3?: boolean;
  news4?: boolean;
  product_order?: boolean;
  product?: boolean;
  cart?: boolean;
  customer?: boolean;
  group?: boolean;
  MemberProfileSettings?: boolean;
  MemberSettings?: boolean;
  place?: boolean;
  shopSetting?: boolean;
  benefit?: boolean;
  topic?: boolean;
  post?: boolean;
  leaderboard?: boolean;
  links?: boolean;
  links2?: boolean;
  links3?: boolean;
  links4?: boolean;
  places?: boolean;
  customMenu?: boolean;
  menuAlias?: boolean;
  restaurantSettings?: boolean;
  restaurantProduct?: boolean;
  restaurantTable?: boolean;
  restaurantMeal?: boolean;
  compeitition?: boolean;
  stream?: boolean;
  stream2?: boolean;
  stream3?: boolean;
  stream4?: boolean;
  cpsurl?: boolean;
  golfClass?: boolean;
  golfClassLevel?: boolean;
  golfClassType?: boolean;
  golfTeacher?: boolean;
  golfTheme?: boolean;
  myGolfClass?: boolean;
  guestReservation? : boolean;
  guestCheckinMessage? : boolean;
  guestPrecheckin? : boolean;
}

@Injectable({
  providedIn: 'root'
})
export class StorageLibService {

  /**
   * when page is reload, this flag will become true
   */
  cache: ServiceReloadFlag = {};

  constructor(private logLib: LogLibService) {  }
  /**
   * set data to local storage
   * @param key key to set
   * @param data data to set
   */
  set(key: string, data: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      this.logLib.error('[StorageService]', 'set error:', e);
    }
  }
  /**
   * get data from local storage
   * @param key key to set
   * @return data data to set
   */
  get(key: string) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      this.logLib.error('[StorageService]', 'get error:', e);
    }
  }
  /**
   * clear local storage (except version-m,version-n)
   */
  clear() {
    // keep version
    const vms = [this.get('version-m'), this.get('version-n')];
    localStorage.clear();
    this.cache = {};

    this.set('version-m', vms[0]);
    this.set('version-n', vms[1]);
  }

}
