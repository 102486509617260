<app-toolbar page="order-history"></app-toolbar>

<div class="form-list gray" *ngIf="orders$ | async as orders">
  <mat-action-list [class]="'order-list'">

    <div class="card" *ngFor="let order of orders">
      <mat-card [class]="'card-bottom' + (isCancel(order)?' disable':'')">
        <div class="table" (click)="reOrder(order.getId())">
          <div class="row" *ngIf="canPay(order)">
            <div class="col col1"></div>
            <div class="col col2">
            <mat-icon class="float-right col-payment-icon">lens</mat-icon>
            </div>
          </div>
          <div class="row">
              <div class="col col1 col-date">
                {{ order.getOrderdatetime() | amFromUnix | amDateFormat: dateFormat }}
              </div>
              <div class="col col2">
                <span
                  [class]="order.getDeliverytracking() ? 'can-click': 'no-click'"
                  (click)="showTracking($event, order.getDeliverytracking())">
                  {{ textOrderStatus( order.getOrderstatus()) }}
                </span>
              </div>
          </div>

          <div class="row">
            <div class="col col1">
              {{ order.getOrdernumber() }}
            </div>
        </div>

        <div class="row row2">
          <div class="col col1">
            <div class="table">
              <div class="row">
                <div class="col col1 pay-button">
                  <button (click)="pay($event, order.getPaymenturl())" [class]="canPay(order) ? 'text-danger' : ''">
                    {{ textPaymentStatus( order.getPaymentstatus()) }}
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col col1">
                  {{ order.getNbitems() }}
                  {{ (order.getNbitems() > 1 ? 'cart.items' : 'cart.item') | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="col col2">
            <button mat-stroked-button>
              {{ ((order.getTotalprice() | number:'1.2-2') || '').replace(',',' ').replace('.',',') }} {{ currencysymbol }}
            </button>
          </div>
        </div>

      </div>
      </mat-card>
    </div>
  </mat-action-list>
</div>

<div class="pay-loading" *ngIf="payLoading">
  <div class="bg"></div>
  <mat-spinner class="wait"></mat-spinner>
</div>
