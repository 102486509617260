<app-toolbar page="golf_class" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''"></app-toolbar>

<div *ngIf=" classloading">
  <mat-spinner style="margin: auto"></mat-spinner>  
</div>

<div class="cal-list" [ngClass]="{'mtype2': menuType==2}" *ngIf="!showCalendar && !classloading">
<div class="top-menu">
  <mat-chip-list>
    <button (click)="show_filter()" mat-raised-button>{{ 'golf_class.filter' | translate }}</button>
    <mat-chip 
    *ngIf="dlgSelect.teacher">{{ teachername(dlgSelect.teacher, dlgteachers) }}
    <button matChipRemove (click)="dlgSelect.teacher='';updateFilter()">      
      <mat-icon>cancel</mat-icon>
    </button></mat-chip>
    <mat-chip 
    *ngIf="dlgSelect.theme">{{ themename(dlgSelect.theme) }}
    <button matChipRemove (click)="dlgSelect.theme='';updateFilter()">      
      <mat-icon>cancel</mat-icon>
    </button></mat-chip>
    <mat-chip 
    *ngIf="dlgSelect.level">{{ levelname(dlgSelect.level) }}
    <button matChipRemove (click)="dlgSelect.level='';updateFilter()">      
      <mat-icon>cancel</mat-icon>
    </button></mat-chip>
    <mat-chip 
    *ngIf="dlgSelect.type">{{ typename(dlgSelect.type) }}
    <button matChipRemove (click)="dlgSelect.type='';updateFilter()">      
      <mat-icon>cancel</mat-icon>
    </button></mat-chip>    
  </mat-chip-list>
</div>

<div class="box-day">
  <div class="top-month">
    <div class="month">
      {{ dlgSelect.monthname }} {{ dlgSelect.date.year() }}
    </div>
    <button (click)="showCalendar=true"
      mat-icon-button style="float: right;margin-top: -33px;right: 5px">
      <mat-icon>calendar_month</mat-icon>
    </button>
  </div>
  <br/>  
  <div class="days">
    <div class="col left" style="margin-right: 10px">
      <button mat-icon-button (click)="nextweek(-1)">
        <mat-icon style="font-size: 45px" [ngStyle]="{'margin-top': isios ? '0px' : '-10px','color': backgroundColor}">arrow_back_ios</mat-icon>
      </button>
    </div>
    <div class="col" 
      (click)="dlgsetdate(d)"
      [ngClass]="{active : dlgSelect.isselect(d.format('YYYYMMDD'))}" *ngFor="let d of dlgSelect.vdays">
      <div class="no" >
        {{ d.format('DD') }}
      </div>
      <div class="name">
        {{ d.format('ddd') }}
      </div>
    </div>          
    <div class="col right">
      <button mat-icon-button (click)="nextweek(1)">
        <mat-icon style="font-size: 45px" [ngStyle]="{'margin-top': isios ? '-0px' : '-10px','color': backgroundColor}">arrow_forward_ios</mat-icon>
      </button>
    </div>
  </div>        

  <mat-spinner *ngIf="classloading" style="margin: auto"></mat-spinner>

  <div class="no-times" *ngIf="dlgSelect.times(dlgclasses).length == 0">
    {{ 'golf_class.no_class' | translate }}
  </div>
  <div class="times"
    *ngIf="dlgSelect.times(dlgclasses).length > 0">    

    <ng-container 
    *ngFor="let c of dlgSelect.times(dlgclasses)"
    [ngTemplateOutletContext]="{
      class: c
    }" [ngTemplateOutlet]="golfclasslist">
  </ng-container>

  </div>
</div>
</div>
<div>

</div>
<div class="cal-view" [ngClass]="{'mtype2': menuType==2}" *ngIf="showCalendar">
  <div class="calendar-table">
    <div class="row">
      <button class="col" (click)="prevMonth()" mat-button>
        <mat-icon>chevron_left</mat-icon>
      </button>
      <h2 class="top col">
        {{ calendarDate | calendarDate:(calendarView + 'ViewTitle'): userLocale }}
      </h2>
      <button class="col" (click)="nextMonth()" mat-button>
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>
  <mwl-calendar-month-view       
      [cellTemplate]="cellTemplate"
      [headerTemplate]="headTemplate"
      [locale]="userLocale"
      [viewDate]="calendarDate"
      [events]="calendarEvents"
      [refresh]="calendarRefresh"
      [activeDayIsOpen]="calendarActiveDayIsOpen"
      weekStartsOn="1"
      (dayClicked)="calendarDayClicked($event.day)">
    </mwl-calendar-month-view>

</div>

<ng-template #cellTemplate let-day="day" let-locale="locale" >
  <div class="col-day" [ngClass]="{'cal-selected': availEvent(day.date |amDateFormat: 'YYYYMMDD') }"
      [ngStyle]="{
      'background-color': availEvent(day.date |amDateFormat: 'YYYYMMDD') ? backgroundColor: 
                          'white'
      }">
      <span>
        {{ day.date | calendarDate:'monthViewDayNumber':locale }}
      </span>
  </div>
</ng-template>

<ng-template #headTemplate let-days="days" let-locale="locale">
  <span class="col-weekday" *ngFor="let d of days">
    {{ (d.date | amLocale: locale | amDateFormat: 'dd') | uppercase }}
  </span>

</ng-template>

<div class="golf-class-dialog" *ngIf="dlgdisplay=='block'">
  <div class="bg"></div>
  <mat-card [class]="'dlg'">
    <ng-container [ngTemplateOutletContext]="{
      title: 'golf_class.filter' | translate
    }" [ngTemplateOutlet]="dlghead"></ng-container>
    <mat-card-content class="content">
      <br/><br/>
      <div class="box-option" *ngIf="dlgteachers">
        <mat-spinner *ngIf="dlgloading" style="margin: auto"></mat-spinner>
                
        <table *ngIf="!dlgloading">
          <tr><td><label>{{ 'golf_class.teacher' | translate}}</label></td><td><select [(ngModel)]="dlgSelect.teacher">
            <option></option>  
            <option *ngFor="let tech of dlgteachers" [ngValue]="tech.getId()" >{{ [tech.getFirstname(), tech.getName()].join(' ') }}</option>
          </select></td></tr>
          <tr><td><label>{{ 'golf_class.theme' | translate }}</label></td><td><select [(ngModel)]="dlgSelect.theme">
            <option></option>
            <option *ngFor="let t of dlgthemes" [ngValue]="t.getId()">{{ t.getName() }}</option>
          </select></td></tr>
          <tr><td><label>{{'golf_class.level' | translate}}</label></td><td><select [(ngModel)]="dlgSelect.level">
            <option></option>
            <option *ngFor="let l of dlglevels" [ngValue]="l.getId()">{{ l.getName() }}</option>
          </select></td></tr>
          <tr><td><label>{{'golf_class.type' | translate}}</label></td><td><select [(ngModel)]="dlgSelect.type">
            <option></option>
            <option *ngFor="let t of dlgtypes" [ngValue]="t.getId()">{{ t.getName() }}</option>
          </select></td></tr>
      </table>      
      </div>
    </mat-card-content>

    <ng-container *ngIf="!dlgloading" [ngTemplateOutletContext]="{
      step: step
    }" [ngTemplateOutlet]="dlgfoot"></ng-container>
  </mat-card>
</div>  

<ng-template #dlghead
  let-title="title"
  >
  <mat-card-title class="header">
    <span>{{ title }}</span>
    <button mat-icon-button (click)="close_dlg()">
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-card-title>  
</ng-template>

<ng-template #dlgfoot
  let-step="step"
  ><mat-card-footer class="footer">
    <button *ngIf="step != 2" mat-flat-button color="primary" (click)="updateFilter()">
      {{'Dialog.OK' | translate}}
    </button>
  </mat-card-footer>
</ng-template>

<ng-template #golfclasslist
  let-c="class"
  let-readonly="readonly"
  >
  <div class="line" (click)="gcollapse=gcollapse == c.getId() ? '': c.getId()">
      <div class="time">
        {{ format_hour(c.getTimestart()) }}
      </div>
      <div class="col2">
        <span>
        <mat-icon [ngStyle]="{'color': isattend(c, me.myclasses) || !canattend(c) || (c.getQuotamax() ? (c.getQuotamax() - c.getNbattendees()) <= 0 : false) ? 'red' : 'green'}" class="icon-color line-attend">lens</mat-icon>
        <div class="des" style="margin-top:-20px">
        [{{  teachername(c.getGolfteacherid(), dlgteachers) }}]
        {{ c.getName() }}
        </div>
      </span>
        <div class="price">
        <span>{{ c.getPrice() ? c.getPrice() : ('golf_class.price_free' | translate)  }}</span>
        <span *ngIf="c.getPrice()">&nbsp;{{ currencysymbol }}</span>
      </div>      
    </div>
  </div>
  <div class="col2-expand" [ngClass]="{'show':gcollapse ==c.getId()}">
    <app-golf-card    
    [c] = "c"
    [route]="route"
    [colorlib]="colorlib"
    [userLib]="userLib"
    [grpcGolfClassLib]="grpcGolfClassLib"
    [dlglib]="dlglib"
    [storeLib]="storeLib"
    [readonly]="readonly"
    [dlgteachers]="dlgteachers"
    [dlgwaitattend]="dlgwaitattend"
    [T]="T"
    [dlgdisplay]="dlgdisplay"
    [me]="me"
    ></app-golf-card>    
  </div>  
</ng-template>

