<app-toolbar page="places" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''"></app-toolbar>

<div class="form-list" [ngClass]="{tablet: menuType==2}" *ngIf="places$ | async as places ">
  <mat-action-list *ngFor="let place of places">

    <mat-card>
      <div (click)="open(place.getId())"  >
        <mat-card-header>
          <div mat-card-avatar class="img"
            [ngStyle]="{'background-image': 'url(' + (place.getImagesList()[0] | pipeWebp) + ')'}" >
          </div>
          <mat-card-title> {{ place.getName() }}</mat-card-title>
          <mat-card-subtitle>{{ place.getShortdescription() }}</mat-card-subtitle>
        </mat-card-header>
      </div>
    </mat-card>

  </mat-action-list>
</div>

<ng-template #loading>
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
