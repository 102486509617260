import { Component, OnInit, OnDestroy, HostListener, ElementRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CropperComponent } from 'angular-cropperjs';
import * as moment from 'moment';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Subject, delay } from 'rxjs';
import { desktopMode } from 'src/app/config/type';
import { Title } from 'src/app/libs/proto/commUnity_pb';
import { HotixGuest, HotixLevelSatisfaction, HotixPreCheckin, HotixPreCheckinStatus, HotixReservation, HotixReservationDetail, HotixReservationStatus, HotixSatisfactionSurvey } from 'src/app/libs/proto/hotix_pb';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { DialogServiceService } from 'src/app/service/dialog/dialog-service.service';
import { GrpcGuestLibService } from 'src/app/service/grpc/guest/grpc-guest-lib.service';
import { UserLibService } from 'src/app/service/user/user-lib.service';


@Component({
  selector: 'app-guest-satisfaction',
  templateUrl: './satisfaction.component.html',
  styleUrls: ['./satisfaction.component.sass']
})
export class GuestSatisfactionComponent implements OnInit {

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;
  menuType = 0;

  tkeys = {};
  reservation_id = '';
  resaid = '';
  comment = '';
  satisfy = -1;
  satisfys = [
    ['sentiment_very_satisfied', HotixLevelSatisfaction.HOTIXLEVELSATISFACTION_VERY_SATISFIED],
    ['sentiment_satisfied', HotixLevelSatisfaction.HOTIXLEVELSATISFACTION_SATISFIED],
    ['sentiment_dissatisfied', HotixLevelSatisfaction.HOTIXLEVELSATISFACTION_DISSATISFIED],
    ['sentiment_very_dissatisfied', HotixLevelSatisfaction.HOTIXLEVELSATISFACTION_VERY_DISSATISFIED],
  ]

  waitsave = false;

  constructor(
    private route: Router,
    private userLib: UserLibService,
    private detector: DeviceLibService,
    private translate: TranslateService,
    private actRoute: ActivatedRoute,
    private dialogLib: DialogServiceService,
    private grpcLib: GrpcGuestLibService,
  ) {
    
  }
  
  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
  getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4) {
        if (this.isLandscape) { return 2; }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }
  prepareTranslate(callback: ()=>void){
    this.translate.get([
      'guest.satisfaction-sent'
    ]).toPromise().then( v => {
      this.tkeys = v;
    }).finally( () => callback() );
  }
  ngOnInit(): void {
    const thise = this;
    this.actRoute.paramMap.subscribe(p => {
      this.reservation_id = p.get('id');
      this.prepareTranslate( () => {
        thise.grpcLib.getHotixReservation({
          Offline: true,
        }).then(ns => {
          const dd = ns.filter(n => n.getId() === thise.reservation_id);
          // not found any
          if (dd.length === 0) {
            thise.route.navigateByUrl('/guest/reservations');
            return;
          }
          thise.resaid = dd[0].getHotixreservation().getResaid();
        });
      });
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });    
  }
  
  choose(mode: number) {
    this.satisfy = mode;
  }
  get cansend() {
    return this.satisfy > -1;
  }
  send() {
    this.waitsave = true;
    setTimeout(() => {
      let req = new HotixSatisfactionSurvey();

      req.setReservationid(this.reservation_id);
      req.setComment(this.comment);     
      req.setLevelsatisfaction( this.satisfy );

      this.grpcLib.setHotixSatisfactionSurvey(req).then( (e) => {
        this.dialogLib.show(this.tkeys['guest.satisfaction-sent'], null, () => {
          this.route.navigate(['/guest/reservations']);
        });
      }).catch((e) => {
        this.waitsave = false;
        this.dialogLib.show(e);
      });
    }, 200);
  }
}
