import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { fromEvent, of, Subscriber, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { GrpcSearchService } from '../../service/grpc/search/grpc-search.service';
import { SearchResult } from '../../libs/proto/mobile_pb';
import { TranslateService } from '@ngx-translate/core';
import { SearchLib } from './search-lib';
import { UserLibService } from '../../service/user/user-lib.service';
import { Router } from '@angular/router';
import { StorageLibService } from '../../service/storage/storage-lib.service';
import { NewsConversionService } from '../../service/conversion/news/news-conversion.service';
import { ProductConversionService } from '../../service/conversion/product/product-conversion-service';
import { PlaceConversionService } from '../../service/conversion/place/place-conversion.service';
import { AlbumConversionService } from '../../service/conversion/album/album-conversion.service';
import { BenefitConversionService } from '../../service/conversion/benefit/benefit-conversion.service';
import { MemberConversionService } from '../../service/conversion/member/member-conversion.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Platform } from '@angular/cdk/platform';
import { GrpcMenuLibService } from '../../service/grpc/menu/grpc-menu-lib.service';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass']
})
export class SearchComponent implements OnInit, AfterViewInit {

  @ViewChild('searchInput') searchInput: ElementRef;
  waiting: boolean;
  searchText: string;
  prevText: string;

  result$: Promise<SearchResult[]>;
  ts: {k: string, v: string};
  empty: boolean;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;

  constructor(
    private grpcLib: GrpcSearchService,
    private translate: TranslateService,
    private userLib: UserLibService,
    private route: Router,
    private storeLib: StorageLibService,
    private convNewsLib: NewsConversionService,
    private convProductLib: ProductConversionService,
    private convAlbumLib: AlbumConversionService,
    private convBenefitLib: BenefitConversionService,
    private convMemberLib: MemberConversionService,
    private grpcMenuLib: GrpcMenuLibService,
    private platform: Platform,
    private detector: DeviceLibService,
  ) { }
  get currencysymbol() {
    return this.userLib.Data.token?.getCustomer().getCurrencysymbol();
  }
  private callMenuAlias(){
    this.grpcMenuLib.getMenuAlias({
      Offline: true,
    }).then( v => {
      this.userLib.Data.menuAlias = v;
    });
  }
  ngOnInit(): void {
    this.callMenuAlias();
    this.translate.get([
      'news.title',
      'product.title',
    ]).toPromise().then( t => {
      this.ts = t;
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }
  ngAfterViewInit(): void {
    fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
      .pipe(debounceTime(1500))
      .pipe(distinctUntilChanged())
      .subscribe(t => {
        if (this.prevText !== t) {
          this.prevText = t;
          this.search(t);
        }
      });

    if (this.platform.IOS && this.platform.SAFARI) { return; }
    this.searchInput.nativeElement.focus();
  }

  keyup(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.prevText = (this.searchInput.nativeElement as HTMLInputElement).value;
      this.search( this.prevText );
    }
  }

  search(t: string) {
    // show loading
    this.waiting = true;

    // if not same
    if (this.searchText !== t) {
        // cancel previous search
        this.grpcLib.cancel();
        this.result$ = this.grpcLib.search({
          call: {
            req: t
          }
        }).then( s => {
          this.empty = s.length === 0;
          return of(s).toPromise();
        })
        .catch( e => {
          this.empty = true;
          return of([]).toPromise();
        })
        .finally( () => {
          this.waiting = false;
        });
    }
  }

  getType(item: SearchResult): string{
    return this.ts[SearchLib.getTypeValue(item) + '.title'];
  }

  allowed(type: string) {
    return ['album',
            'benefit',
            'member',
            'news',
            'news2',
            'news3',
            'news4',
            'product', 'place']
            .indexOf(type) >= 0;
  }

  getTypeValue(item: SearchResult): string {
    return SearchLib.getTypeValue(item);
  }

  getItemTitle(item: SearchResult): string {
    switch (this.getTypeValue(item)) {
      case 'album':
        return item.getAlbum()?.getTitle();
      case 'benefit':
        return item.getBenefit()?.getTitle();
      case 'member':
        return [item.getMember()?.getFirstname(), item.getMember()?.getLastname()].join(' ');
      case 'news':
      case 'news2':
      case 'news3':
      case 'news4':
        return item.getNews()?.getTitle();
      case 'place':
        return item.getPlace()?.getName();
      case 'product':
        return item.getProduct()?.getName();
    }
  }
  getItemSubtitle(item: SearchResult): string {
    switch (this.getTypeValue(item)) {
      case 'album':
        return item.getAlbum()?.getShortdescription();
      case 'benefit':
        return item.getBenefit()?.getShortdescription();
      case 'member':
        return item.getMember()?.getFunction();
      case 'news':
      case 'news2':
      case 'news3':
      case 'news4':
        return item.getNews()?.getShortdescription();
      case 'product':
        return item.getProduct()?.getShortdescription();
      case 'place':
        return item.getPlace()?.getShortdescription();
    }
  }
  getItemPhoto(item: SearchResult): string {
    switch (this.getTypeValue(item)) {
      case 'album':
        return item.getAlbum()?.getImagesList()[0];
      case 'benefit':
        return item.getBenefit()?.getImage();
      case 'member':
        return item.getMember()?.getPhoto();
      case 'news':
      case 'news2':
      case 'news3':
      case 'news4':
        return item.getNews()?.getImage();
      case 'product':
        return item.getProduct()?.getImagesList()[0];
      case 'place':
        return item.getPlace()?.getImagesList()[0];
    }
  }
  getItemPrice(item: SearchResult): number {
    switch (this.getTypeValue(item)) {
      case 'product':
        return item.getProduct()?.getPrice();
    }
  }
  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }

  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }

  open(item: SearchResult) {
    switch (SearchLib.getTypeValue(item)) {
      case 'album':
        this.storeAlbum(item);
        return this.route.navigateByUrl('/album/' + item.getAlbum().getId());
      case 'benefit':
        this.storeBenefit(item);
        return this.route.navigateByUrl('/benefit/' + item.getBenefit().getId());
      case 'member':
        this.storeMember(item);
        return this.route.navigateByUrl('/member/' + item.getMember().getId());
      case 'news':
      case 'news2':
      case 'news3':
      case 'news4':
        this.storeNews(item);
        const suf = SearchLib.newsIndex(item);
        return this.route.navigateByUrl('/new' + suf + '/' + item.getNews().getId());
      case 'product':
        this.storeProduct(item);
        return this.route.navigateByUrl('/product/' + item.getProduct().getId());
      case 'place':
        return this.route.navigateByUrl('/place/search/0/' + item.getPlace().getId());
    }
  }

  getMenuAlias(t: string) {
    switch (t) {
      case 'album': return this.userLib.Data.menuAlias.getAlbum() || (t + '.title');
      case 'benefit': return this.userLib.Data.menuAlias.getBenefit() || (t + '.title');
      case 'member': return this.userLib.Data.menuAlias.getMember() || (t + '.title');
      case 'news': return this.userLib.Data.menuAlias.getNews() || (t + '.title');
      case 'news2': return this.userLib.Data.menuAlias.getNews2() || 'new.title';
      case 'news3': return this.userLib.Data.menuAlias.getNews3() || 'new.title';
      case 'news4': return this.userLib.Data.menuAlias.getNews4() || 'new.title';
      case 'product': return this.userLib.Data.menuAlias.getShop() || (t + '.title');
      case 'place': return this.userLib.Data.menuAlias.getPlace() || (t + '.title');
    }
  }

  private storeNews(item: SearchResult) {
    const suf = SearchLib.newsIndex(item);
    if (this.storeLib.get('news' + suf)) { return; }

    this.storeLib.set('news-s' + suf, this.convNewsLib.ToStorages(
      [item.getNews()]
    ));
  }

  private storeProduct(item: SearchResult) {
    if (this.storeLib.get('product')) { return; }

    this.storeLib.set('product-s', this.convProductLib.ToStorages(
      [item.getProduct()]
    ));
  }

  private storeAlbum(item: SearchResult) {
    if (this.storeLib.get('album')) { return; }

    this.storeLib.set('album-s', this.convAlbumLib.ToStorages(
      [item.getAlbum()]
    ));
  }

  private storeBenefit(item: SearchResult) {
    if (this.storeLib.get('benefit')) { return; }

    this.storeLib.set('benefit-s', this.convBenefitLib.ToStorages(
      [item.getBenefit()]
    ));
  }

  private storeMember(item: SearchResult) {
    if (this.storeLib.get('member')) { return; }

    this.storeLib.set('member-s', this.convMemberLib.ToStorages(
      [item.getMember()]
    ));
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }
}
